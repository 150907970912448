import { useContext, useEffect } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../App'
import { AMPanel } from '../components/AMPanel'
import { AMToggle } from '../components/form/AMToggle'
import { FormPanel, SearchCriteria, SelectorItemSize, UnitSystem } from '../utils/types'

export const SelectUnitsPanel = ({
  control,
  watch,
  setValue,
}: FormPanel & { watch: UseFormWatch<SearchCriteria> } & {
  setValue: UseFormSetValue<SearchCriteria>
}) => {
  const { t } = useTranslation()
  const { config, setUnitSystem } = useContext(AppContext)
  const unitWatch = watch('unit')

  useEffect(() => {
    setUnitSystem(unitWatch)
  }, [unitWatch])

  useEffect(() => {
    if (config?.unitSystem) {
      setUnitSystem(config?.unitSystem as UnitSystem)
      setValue('unit', config?.unitSystem as UnitSystem)
    }
  }, [config?.unitSystem])

  return (
    <AMPanel title={'1. ' + t('units.title')} subtitle={t('units.subtitle')}>
      <AMToggle
        control={control}
        name={'unit'}
        size={SelectorItemSize.Small}
        defaultValue={(config?.unitSystem as UnitSystem) ?? UnitSystem.SI}
        options={[
          {
            value: UnitSystem.SI,
            label: `${t('units.si')} `,
            subLabel: 'bar, kW, Nm',
          },
          {
            value: UnitSystem.Imperial,
            label: `${t('units.imperial')} `,
            subLabel: 'psi, hp, lbf, ft',
          },
        ]}
      />
    </AMPanel>
  )
}
