import { theme } from './theme'
import { createTheme } from '@material-ui/core'

export const smTheme = createTheme({
  colors: theme.colors,
  palette: {
    background: {
      default: 'white',
    },
    secondary: {
      main: '#ED1C2E',
      light: 'rgba(237, 28, 46, 0.1)',
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: theme.spacing(2),
      },
    },
    MuiCard: {
      root: {
        backgroundColor: 'white',
        padding: theme.spacing(0) + 'px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        borderRadius: 'none',
        border: 'none',
        '& h2': {
          marginTop: theme.spacing(2),
        },
      },
    },
    MuiGrid: {
      root: {
        '& > div': { width: '100%' },
      },
      'grid-xs-12': {
        '& > button': { width: '100%' },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginRight: '-8px',
      },
    },
  },
  typography: {
    fontFamily: 'Arial',
    h1: {
      fontFamily: 'Arial',
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '37px',
      paddingLeft: theme.spacing(0) + 'px',
      paddingBottom: theme.spacing(2),
    },
    h2: {
      fontFamily: 'Arial',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: theme.spacing(2) + 'px',
      paddingBottom: theme.spacing(2),
    },
    h4: {
      fontFamily: 'Arial',
      fontWeight: 'bold',
      fontSize: '17px',
      lineHeight: theme.spacing(2) + 'px',
      paddingBottom: theme.spacing(2),
    },
    caption: {
      fontSize: '36px',
      lineHeight: '46px',
    },
    subtitle1: {
      fontFamily: 'Arial',
      fontSize: '19px',
      lineHeight: theme.spacing(3) + 'px',
      paddingBottom: theme.spacing(1),
    },
    subtitle2: {
      fontFamily: 'Arial',
      fontSize: '17px',
      lineHeight: theme.spacing(3) + 'px',
      paddingBottom: theme.spacing(1),
    },
    h5: {
      fontFamily: 'Arial',
      fontSize: '15px',
      lineHeight: theme.spacing(2) + 'px',
      paddingBottom: '8px',
      fontStyle: 'italic',
    },
    body1: {
      fontSize: '15px',
      lineHeight: theme.spacing(4) + 'px',
      fontFamily: 'Helvetica',
      fontWeight: 'normal',
    },
    body2: {
      fontSize: '16px',
      lineHeight: theme.spacing(4) + 'px',
      fontWeight: 600,
    },
  },
})
