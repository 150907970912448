import { makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import languages from '../assets/languages.json'

interface LanguageViewType {
  setLanguage: (lg: string) => void
}

const useStyles = makeStyles((theme) => ({
  languageView: {
    backgroundColor: 'white',
  },
  languageViewRow: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '16px 0px',
    borderBottom: '1px solid ' + theme.palette.grey[300],
    cursor: 'pointer',
  },
}))

export const LanguageView = ({ setLanguage }: LanguageViewType) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.languageView}>
      <Typography variant="h2">{t('title.language')}</Typography>
      {languages.map((lg) => (
        <div
          className={classes.languageViewRow}
          key={'select-' + lg.id}
          onClick={() => setLanguage(lg.id)}
        >
          {lg.name}
        </div>
      ))}
    </div>
  )
}
