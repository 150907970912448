import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AMButton, ThemeButton } from '../components/AMButton'
import { AMSelector } from '../components/AMSelector'
import { AirMotor } from '../utils/types'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { theme } from '../utils/theme'

type MotorSelectType = {
  selectedMotor: AirMotor | null
  motors: AirMotor[]
  onChange: (id: string) => void
}

const useStyles = makeStyles(() => ({
  motorSelect: {
    textAlign: 'center',
  },
}))

export const MotorSelect = ({ selectedMotor, motors, onChange }: MotorSelectType) => {
  const [opened, setOpened] = useState(false)
  const [motorsDisplayed, setMotorsDisplayed] = useState(motors)
  const { t } = useTranslation()
  const classes = useStyles()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const options = motorsDisplayed.map((m) => ({
    value: m.name,
    label: m.name,
    img: m.img ? 'https://catalogs.cp.com/img/cp/products/' + m.img : m.name + '.jpg', // if img not defined, get img from /public
  }))

  useEffect(() => {
    if (opened) {
      setMotorsDisplayed(motors)
    } else {
      setMotorsDisplayed(motors.slice(0, 4))
    }
  }, [opened, motors])

  return (
    <div className={classes.motorSelect}>
      <AMSelector
        value={selectedMotor ? [selectedMotor.name] : []}
        options={options}
        onChange={(name: string) => onChange(motors.find((m) => m.name === name)!.id)}
        carousel={isMobileView}
      />
      {motors.length > 4 && !isMobileView && (
        <AMButton
          label={opened ? t('common.seeLess') : t('common.seeMore')}
          theme={ThemeButton.Black}
          onClick={() => setOpened(!opened)}
        />
      )}
    </div>
  )
}
