import * as React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/styles'

type AMTooltipType = {
  content: string
  img?: string
}

const useStyles = makeStyles(() => ({
  tooltip: {
    width: '100%',
    fontSize: '13px',
    '& img': {
      width: '100%',
    },
  },
}))
export const AMTooltip = ({ content, img }: AMTooltipType) => {
  const classes = useStyles()
  return (
    <Tooltip
      title={
        <div className={classes.tooltip}>
          {/*Yeah, using dangerouslySetInnerHTML attr is insecure but data provide only from translations*/}
          <div dangerouslySetInnerHTML={{ __html: content?.replace(/{br}/g, '</br></br>') }}></div>
          {img && <img src={img} />}
        </div>
      }
      arrow
    >
      <IconButton>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  )
}
