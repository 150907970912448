import { makeStyles, Typography } from '@material-ui/core'
import { AMButton } from '../components/AMButton'
import catalog from '../assets/catalogue.json'
import { theme } from '../utils/theme'
import { AMFixedFooter } from '../components/AMFixedFooter'
import { useTranslation } from 'react-i18next'

type HomePageType = {
  onClick: () => void
}

const useStyles = makeStyles(() => ({
  root: {
    '& img': {
      width: '100%',
    },
  },
  fixedFooter: {
    textAlign: 'center',
    position: 'fixed',
    bottom: '0px',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    width: '100%',
  },
}))

export const HomePage = ({ onClick }: HomePageType) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <div className={classes.root}>
        <img src="./home.png" />
        <Typography variant="subtitle1">
          {t('home.subtitle').replace('{length}', catalog.length + '')}
        </Typography>

        <Typography variant="h1"> {t('home.title')}</Typography>
      </div>
      <AMFixedFooter>
        <AMButton label={t('home.btnFind')} onClick={onClick} />
      </AMFixedFooter>
    </>
  )
}
