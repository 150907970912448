import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useContext } from 'react'
import { useEffect, useState } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../App'
import { AMButton, ThemeButton } from '../components/AMButton'
import { AMPanel } from '../components/AMPanel'
import { AMSeparator } from '../components/AMSeparator'
import { AMTooltip } from '../components/AMTooltip'
import { AMTextField } from '../components/form/AMTextField'
import { useUnitText } from '../hooks/useUnitText'
import { powerByTorqueSpeed, speedByTorquePower, torqueBySpeedPower } from '../utils/lib'
import { theme } from '../utils/theme'
import { FormPanel, SearchCriteria, Units } from '../utils/types'

const useStyles = makeStyles(() => ({
  root: {
    '& .hide': {
      display: 'none',
    },
  },
  formBloc: {
    '& > div': {
      marginBottom: '20px',
      width: '100%',
    },
  },
  right: {
    textAlign: 'right',
  },
  showOpts: {
    textAlign: 'center',
    color: theme.palette.grey[500],
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    '& img': {
      padding: theme.spacing(0.25),
    },
  },
  align: {
    display: 'flex',
  },
  titleWithBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '2px',
  },
}))

enum FromField {
  WorkingPoint = 'workingPoint',
  MinStartTorque = 'minStartTorque',
  MinStallTorque = 'minStallTorque',
  FreeSpeed = 'freeSpeed',
}
export const SelectWorkingPointPanel = ({
  control,
  watch,
  setValue,
}: FormPanel & {
  watch: UseFormWatch<SearchCriteria>
  setValue: UseFormSetValue<SearchCriteria>
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { unitSystem } = useContext(AppContext)

  // TODO : use useWatch(field, defaultValue)
  const speedWatch = watch('speed')
  const torqueWatch = watch('torque')
  const powerWatch = watch('power')

  const stallTorqueMinWatch = watch('minStallTorque')
  const startTorqueMinWatch = watch('minStartTorque')
  const freeSpeedWatch = watch('freeSpeed')

  // a "from field" (or "origin field") is a field used to calculte another
  // ex : if a = b / 2, b is origin field of a
  const [fromFieldTorque, setFromFieldTorque] = useState<FromField | undefined>()
  const [fromFieldSpeed, setFromFieldSpeed] = useState<FromField | undefined>()
  const [fromFieldPower, setFromFieldPower] = useState<FromField | undefined>()

  const [showOptionalsFields, setShowOptionalsFields] = useState(false)

  /**
   * This code is not really easy to read, but I don't know how refactor it
   * We check here if a originField is stored (for torque, speed and power)
   * If exist, we apply calculation rule (3 for torque, 2 for speed, 1 for power)
   * And, if exist origin field but its value is null, we retire it
   *
   */
  const computeMissedValue = () => {
    // torque
    if (fromFieldTorque) {
      if (fromFieldTorque === FromField.MinStallTorque) {
        if (stallTorqueMinWatch) {
          setValue('torque', stallTorqueMinWatch / 2)
        } else {
          setValue('torque', undefined)
          setFromFieldTorque(undefined)
        }
      }
      if (fromFieldTorque === FromField.MinStartTorque) {
        if (startTorqueMinWatch) {
          setValue('torque', startTorqueMinWatch / 2)
        } else {
          setValue('torque', undefined)
          setFromFieldTorque(undefined)
        }
      }

      if (fromFieldTorque === FromField.WorkingPoint) {
        if (speedWatch && powerWatch) {
          setValue('torque', torqueBySpeedPower(speedWatch, powerWatch, unitSystem))
        } else {
          setValue('torque', undefined)
          setFromFieldTorque(undefined)
        }
      }
    }

    // speed
    if (fromFieldSpeed) {
      if (fromFieldSpeed === FromField.FreeSpeed) {
        if (freeSpeedWatch) {
          setValue('speed', freeSpeedWatch / 2)
        } else {
          setValue('speed', undefined)
          setFromFieldSpeed(undefined)
        }
      }
      if (fromFieldSpeed === FromField.WorkingPoint) {
        if (torqueWatch && powerWatch) {
          setValue('speed', speedByTorquePower(torqueWatch, powerWatch, unitSystem))
        } else {
          setValue('speed', undefined)
          setFromFieldSpeed(undefined)
        }
      }
    }

    // power
    if (fromFieldPower) {
      if (speedWatch && torqueWatch) {
        if (fromFieldPower === FromField.WorkingPoint) {
          setValue('power', powerByTorqueSpeed(torqueWatch, speedWatch, unitSystem))
        }
      } else {
        setValue('power', undefined)
        setFromFieldPower(undefined)
      }
    }
  }

  const reset = () => {
    setValue('torque', undefined)
    setValue('speed', undefined)
    setValue('power', undefined)
    setValue('minStartTorque', undefined)
    setValue('minStallTorque', undefined)
    setValue('maxStallTorque', undefined)
    setValue('speedAtMaxPower', undefined)
    setValue('freeSpeed', undefined)
    setValue('pressure', undefined)
    setFromFieldTorque(undefined)
    setFromFieldSpeed(undefined)
    setFromFieldPower(undefined)
  }

  /**
   * Set what field is calculated, and from which field
   * be careful : a field manually field must not be "calculated"
   * so, each if means : "if field have not origin field AND field is empty"
   * finally, in each sub "if", means "if a fieled is filled, so it become its origin field"
   */
  useEffect(() => {
    if (!fromFieldTorque && !torqueWatch) {
      if (stallTorqueMinWatch) setFromFieldTorque(FromField.MinStallTorque)
      if (startTorqueMinWatch) setFromFieldTorque(FromField.MinStartTorque)
      if (powerWatch && speedWatch) setFromFieldTorque(FromField.WorkingPoint)
    }
    if (!fromFieldSpeed && !speedWatch) {
      if (freeSpeedWatch) {
        setFromFieldSpeed(FromField.FreeSpeed)
      }
      if (powerWatch && torqueWatch) setFromFieldSpeed(FromField.WorkingPoint)
    }
    if (!fromFieldPower && !powerWatch) {
      if (speedWatch && torqueWatch) setFromFieldPower(FromField.WorkingPoint)
    }

    computeMissedValue()
  }, [
    speedWatch,
    torqueWatch,
    powerWatch,
    startTorqueMinWatch,
    stallTorqueMinWatch,
    freeSpeedWatch,
  ])

  useEffect(() => computeMissedValue(), [fromFieldTorque, fromFieldPower, fromFieldSpeed])

  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <AMPanel
      title={'2. ' + t('workingPoint.title')}
      subtitle={
        <div className={classes.titleWithBtn}>
          <Typography variant="subtitle1">
            {t('workingPoint.subtitle')}
            <AMTooltip content={t('workingPoint.tooltip')} img={'tooltipWP.jpg'} />
          </Typography>

          {!isMobileView && (
            <AMButton
              theme={ThemeButton.White}
              label={
                <div className={classes.align}>
                  <DeleteIcon />
                  {t('workingPoint.cleanAllFields')}
                </div>
              }
              onClick={() => reset()}
              circle
            />
          )}
        </div>
      }
    >
      <form className={classes.root}>
        <>
          <Grid container spacing={isMobileView ? 3 : 5}>
            <Grid item md={3} xs={12}>
              <AMTextField
                type="number"
                name="torque"
                control={control}
                label={`${t('motor.torque')} (${useUnitText(Units.Torque)})`}
                computed={!!fromFieldTorque}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <AMTextField
                type="number"
                name="speed"
                control={control}
                label={`${t('motor.speed')} (${t('unit.si.speed')})`}
                computed={!!fromFieldSpeed}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <AMTextField
                type="number"
                name="power"
                control={control}
                label={`${t('motor.power')} (${useUnitText(Units.Power)})`}
                computed={!!fromFieldPower}
              />
            </Grid>
            <Grid item md={3} xs={12} className={classes.right}></Grid>
          </Grid>

          <AMSeparator />
          {!isMobileView ? (
            <Typography variant="subtitle1">{t('workingPoint.subtitleOptional')}</Typography>
          ) : (
            <>
              <div
                className={classes.showOpts}
                onClick={() => setShowOptionalsFields(!showOptionalsFields)}
              >
                {showOptionalsFields ? (
                  <>
                    <img src="./chevronUp.png" />
                    <span> {t('button.hideOpts')} </span>
                    <img src="./chevronUp.png" />
                  </>
                ) : (
                  <>
                    <img src="./chevronUp.png" />
                    <span> {t('button.showOpts')} </span>
                    <img src="./chevronUp.png" />
                  </>
                )}
              </div>
              {!showOptionalsFields && <AMSeparator />}
            </>
          )}

          <Grid
            container
            spacing={isMobileView ? 3 : 5}
            className={!isMobileView || showOptionalsFields ? '' : 'hide'}
          >
            <Grid item md={3} xs={12}>
              <div className={`${classes.formBloc} `}>
                <Typography variant="h4">{t('motor.torque')}</Typography>
                <AMTextField
                  type="number"
                  name={'minStartTorque'}
                  control={control}
                  label={`${t('workingPoint.minStartingTorque')} (${useUnitText(Units.Torque)})`}
                />
                <AMTextField
                  type="number"
                  name={'minStallTorque'}
                  control={control}
                  label={`${t('workingPoint.minStallTorque')} (${useUnitText(Units.Torque)})`}
                />
                <AMTextField
                  type="number"
                  name={'maxStallTorque'}
                  control={control}
                  label={`${t('workingPoint.maxStallTorque')} (${useUnitText(Units.Torque)})`}
                />
              </div>
            </Grid>

            <Grid item md={3} xs={12}>
              <div className={`${classes.formBloc} `}>
                <Typography variant="h4">{t('motor.speed')}</Typography>
                <AMTextField
                  type="number"
                  name={'speedAtMaxPower'}
                  control={control}
                  label={`${t('workingPoint.speedAtMaxPower')} (${t('unit.si.speed')})`}
                />
                <AMTextField
                  type="number"
                  name={'freeSpeed'}
                  control={control}
                  label={`${t('motor.freeSpeed')} (${t('unit.si.speed')})`}
                />
              </div>
            </Grid>

            <Grid item md={3} xs={12}>
              <div className={`${classes.formBloc} `}>
                <Typography variant="h4">{t('motor.pressure')}</Typography>
                <AMTextField
                  type="number"
                  name={'pressure'}
                  control={control}
                  label={`${t('workingPoint.availablePressure')} (${useUnitText(Units.Pressure)})`}
                />
              </div>
            </Grid>
          </Grid>
        </>
      </form>
    </AMPanel>
  )
}
