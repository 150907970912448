import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { classicNameResolver } from 'typescript'
import { AppContext } from '../App'
import { AMButton, ThemeButton } from '../components/AMButton'
import { AMLinkButton } from '../components/AMLinkButton'
import { AMSelectorItem } from '../components/AMSelectorItem'
import { AMSeparator } from '../components/AMSeparator'
import { useConvertSIToSystem } from '../hooks/useConvertSIToSystem'
import { CriteriasResume } from '../panels/CriteriasResume'
import { MoreInfos, MoreInfosPanel } from '../panels/MoreInfosPanel'
import { MotorDetails } from '../panels/MotorDetails'
import { MotorSelect } from '../panels/MotorSelect'
import {
  getMotorInUnit,
  getUrlParam,
  search,
  searchPressureForUserTorque,
  searchThrottleForUserTorque,
  sendGTMEvent,
} from '../utils/lib'
import { theme } from '../utils/theme'
import { AirMotor, SearchCriteria, SelectorItemSize, Units } from '../utils/types'

type ResultsPageType = {
  criterias: SearchCriteria
  onClickBack: () => void
}

const useStyles = makeStyles(() => ({
  titleBtn: {
    display: 'flex',
    justifyContent: ' space-between',
  },
}))
export const ResultsPage = ({ criterias, onClickBack: onClick }: ResultsPageType) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [regulatedPressure, setRegulatedPressure] = useState(0)
  const [throttle, setThrottle] = useState(0)
  // here, motor in right unit thanks to getMotorInUnit() in setMotor
  const [motor, setMotor] = useState<AirMotor | null>(null)
  const [motors, setMotors] = useState<AirMotor[]>([])
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const { unitSystem, config } = useContext(AppContext)

  const defaultPressure = useConvertSIToSystem(6.3, Units.Pressure, true)

  const isSharedLink = getUrlParam('shared')
  const motorNameFromParam = getUrlParam('motor')

  useEffect(() => {
    const motor = motors.find((m) => m.name === motorNameFromParam)
    if (motor) setMotor(motor)
  }, [motorNameFromParam, motors])

  // refresh motor list when criterias changed
  useEffect(() => {
    console.log('Searching with criterias: ', criterias)

    // looking for motors and set result list in state
    const motorsOk = search(criterias)
    setMotors(motorsOk)

    sendGTMEvent({ event: 'search', ...criterias, nbResults: motorsOk.length, name: config?.name })
    if (!motor && motorsOk.length > 0) {
      // no selected motor, check url if motor selected
      const motorFromUrl = motors.find((m) => m.name === getUrlParam('motor'))
      setMotor(motorFromUrl || getMotorInUnit(motorsOk[0], unitSystem))
    }
  }, [criterias])

  useEffect(() => {
    if (motor && criterias && criterias.torque && criterias.speed) {
      const p = searchPressureForUserTorque(
        motor,
        criterias.torque,
        criterias.speed,
        unitSystem,
        criterias.pressure
      )
      setRegulatedPressure(p)

      let t = searchThrottleForUserTorque(
        motor,
        criterias.torque,
        criterias.speed,
        unitSystem,
        criterias.pressure
      )
      if (t === 1) t = -1
      setThrottle(t)
    }
  }, [motor, criterias])

  const onSelectMotor = (idMotor: string) => {
    const motorSelected = getMotorInUnit(motors.find((m) => m.id === idMotor)!, unitSystem)
    setMotor(motorSelected)
    sendGTMEvent({
      event: 'motorSelect',
      model: motorSelected.name,
      partNumber: motorSelected.productName,
      name: config?.name,
    })
  }
  return (
    <>
      {!isSharedLink && (
        <div>
          <AMButton
            rounded
            icon={<ArrowBackIcon />}
            theme={ThemeButton.White}
            label={t('button.back')}
            onClick={onClick}
          />
        </div>
      )}

      {motors.length > 0 && (
        <>
          <>
            {/* Page title */}
            <div>
              <Typography variant="caption">
                {t(isSharedLink ? 'search.correspondanceTitle' : 'search.result').replace(
                  '{length}',
                  motors.length + ''
                )}
              </Typography>
            </div>
            {isSharedLink && motor && (
              <>
                <Grid container spacing={3} style={{ marginTop: 'inherit' }}>
                  <Grid item md={3} xs={12}>
                    <AMSelectorItem
                      disabled={true}
                      size={SelectorItemSize.Large}
                      img={
                        motor.img
                          ? 'https://catalogs.cp.com/img/cp/products/' + motor.img
                          : 'placeholder.png'
                      }
                      label={motor.name}
                    />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <CriteriasResume criterias={criterias} />
                  </Grid>
                </Grid>
              </>
            )}
            {!isSharedLink && (
              <>
                {criterias.freeSpeed && (
                  <Typography variant="h5">{t('search.infoTolerance')}</Typography>
                )}
                {isMobileView && <AMSeparator />}

                {/* Motors carroussel */}
                <div>
                  <Typography variant="h1">{t('selectModel.title')}</Typography>
                  <MotorSelect
                    selectedMotor={motor}
                    motors={motors}
                    onChange={(idMotor) => onSelectMotor(idMotor)}
                  />
                </div>
              </>
            )}
          </>
          {/* Motor panel */}
          <div>
            <div className={classes.titleBtn}>
              <Typography variant="h1">{t('details.title')}</Typography>
              {!isSharedLink && <AMLinkButton criterias={criterias} motor={motor!} />}
            </div>
            <MotorDetails
              motor={motor}
              pressure={criterias?.pressure || defaultPressure}
              regulatedPressure={regulatedPressure}
              throttle={throttle}
              userSpeed={criterias.speed}
              userTorque={criterias.torque}
              criterias={criterias}
            />
          </div>
          {/* Next step panel */}
          {config && config.cta && <MoreInfosPanel type={MoreInfos.NextSteps} motor={motor} />}
        </>
      )}

      {motors.length === 0 && (
        <>
          <div>
            <Typography variant="caption">{t('search.noResult')}</Typography>
          </div>
          <MoreInfosPanel type={MoreInfos.NoResults} />
        </>
      )}
    </>
  )
}
