import { Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from '../utils/theme'
import { AMButton, ThemeButton } from './AMButton'

type AMStepPanelType = {
  id: string
  title: string
  labelBtn: string
  children: JSX.Element
  onClick: (() => void) | undefined
}

const useStyles = makeStyles(() => ({
  stepPanel: {
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      height: '100%',
    },
    '& button': {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      border: '1px solid ' + theme.palette.grey[300],
      borderRadius: theme.spacing(2),
    },
  },
}))

export const AMStepPanel = ({ id, title, labelBtn, children, onClick }: AMStepPanelType) => {
  const classes = useStyles()

  return (
    <Card key={id} variant="outlined" className={classes.stepPanel}>
      <Typography variant="h2">{title}</Typography>
      {children}
      <AMButton
        id={id}
        disabled={!onClick}
        theme={ThemeButton.Red}
        label={labelBtn}
        onClick={onClick ?? (() => console.log('Not implemented'))}
      />
    </Card>
  )
}
