import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AMSelector, AMSelectorOption } from '../components/AMSelector'
import { Accessories, AccessoryType, SelectorItemSize } from '../utils/types'

type AccessoriesType = {
  accessories: Accessories
  onChange: (v: string) => void
}

export const AccessoriesView = ({ accessories, onChange }: AccessoriesType) => {
  const { t } = useTranslation()

  const options: AMSelectorOption[] = []
  Object.values(AccessoryType).forEach((v) => {
    if (accessories[v])
      options.push({
        value: '',
        label: t('accessory.' + v),
        subLabel: accessories[v],
        img: 'https://catalogs.cp.com/ws/image/?product=' + accessories[v],
      })
  })

  return (
    <div>
      <Typography variant="h2">{t('details.compatibleAccessories')}</Typography>
      <Typography variant="h5">{t('details.checkAttached')}</Typography>
      <AMSelector
        disabled={true}
        size={SelectorItemSize.Large}
        value={[]}
        options={options}
        onChange={(name: string) => onChange(name)}
      />
    </div>
  )
}
