import { Grid, makeStyles } from "@material-ui/core";
 
export type LegendTPAProps = {
  data: Chart.ChartDataSets[];
  category: string;
}

export type LegendItemProps = {
  item: Chart.ChartDataSets;
}

const useStyles = makeStyles((theme) => ({
  container: {
     paddingLeft: '10px'
  },
  category: {
    fontSize: 10,
    fontWeight: "bold",
    lineHeight: '20px'
  },
  gridContainer: {
  },
  canvas: {
    color: 'red',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  gridItem: {
    fontSize: 9,
    fontWeight: "lighter",
    lineHeight: '25px',
    paddingLeft: '10px'
  }
}))

const LegendItem = ({ item }: LegendItemProps) => {

  const color = item.borderColor?.toString();
  const colorValue = color?.substring(1, color.length);
  const borderDash = item.borderDash || [1, 1]
  
  return (
    <div style={{
                width: 25,
                height: 10,
                backgroundImage: `url("data:image/svg+xml,%3csvg\
                  width='100%25'\
                  height='100%25'\
                  xmlns='http://www.w3.org/2000/svg'%3e%3crect\
                  width='100%25'\
                  height='100%25'\
                  fill='none'\
                  stroke='%23${colorValue}'\
                  stroke-width='2'\
                  stroke-dasharray='${borderDash[0]}%2c ${borderDash[1]}'\
                  stroke-dashoffset='2'\
                  stroke-linecap='square'/%3e%3c/svg%3e")\
                `
    }}></div>
  )
}

const LegendTPA = ({data, category}: LegendTPAProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.category}>{ category }</div>
      <Grid container className={classes.gridContainer}>
        { data.map((value) => (
          <Grid container key={`${category}-${value.label}`}>
            <Grid item xs={2} className={classes.canvas}>
              <LegendItem item={value} />
            </Grid>
            <Grid item xs={10} className={classes.gridItem}>
              { value.label }
            </Grid>
          </Grid>
        ))
        }
      </Grid>
    </div>
  )
}

export default LegendTPA;