import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AMPanel } from '../components/AMPanel'
import { AMToggleMultiple } from '../components/form/AMToggleMultiple'
import { FormPanel, Material, ExhaustType, Direction } from '../utils/types'

export const SelectOptionsPanel = ({ control }: FormPanel) => {
  const { t } = useTranslation()

  return (
    <AMPanel title={t('options.title')} subtitle={t('options.subtitle')}>
      <>
        <Typography variant="h2">{t('motor.direction')}</Typography>
        <AMToggleMultiple
          name={'direction'}
          control={control}
          defaultValue={[]}
          options={Object.keys(Direction).map((v) => ({
            value: Direction[v as keyof typeof Direction],
            label: t('motor.' + v.toLowerCase()),
            img: v.toLowerCase() + '.png',
          }))}
        />
        <Typography variant="h2">{t('motor.bodyMaterial')}</Typography>
        <AMToggleMultiple
          name={'material'}
          control={control}
          defaultValue={[]}
          options={Object.keys(Material).map((v: string) => ({
            value: Material[v as keyof typeof Material],
            label: t('motor.' + v.toLowerCase()),
            img: v.toLowerCase() + '.png',
          }))}
        />

        <Typography variant="h2">{t('motor.exhaustType')}</Typography>
        <AMToggleMultiple
          name={'exhaustType'}
          control={control}
          defaultValue={[]}
          options={Object.keys(ExhaustType).map((v: string) => ({
            value: ExhaustType[v as keyof typeof ExhaustType],
            label: t('motor.' + v.toLowerCase()),
            img: v.toLowerCase() + '.png',
          }))}
        />
      </>
    </AMPanel>
  )
}
