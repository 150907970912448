import { Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles, Theme as AugmentedTheme } from '@material-ui/core/styles'
import Carousel from 'react-material-ui-carousel'
import { theme } from '../utils/theme'
import { SelectorItemSize } from '../utils/types'
import { AMSelectorItem as AMSelectorItem } from './AMSelectorItem'

type AMSelectorType = {
  value: unknown[]
  options: AMSelectorOption[]
  onChange: (v: string) => void
  size?: SelectorItemSize
  disabled?: boolean
  carousel?: boolean
}

// type of different options (one select box)
export type AMSelectorOption = {
  value: string
  label: string
  subLabel?: string
  img?: string
  carousel?: boolean
}

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  root: {
    padding: theme.spacing(2),
    color: 'black',
    borderRadius: '4px',
    border: '1px solid ' + theme.palette.grey[300],
    margin: theme.spacing(1),
    marginLeft: 'inherit',
    height: (height) => height + 'px',
    textTransform: 'inherit',
    backgroundColor: 'white',
    '& > span': { flexDirection: 'column', display: 'flex' },
    '&.selected': {
      color: 'black',
      border: '2px solid black',
    },
    '&.disabled': {
      color: 'black',
      border: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
    '& img': {
      height: '106px',
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      width: '100%',
    },
  },
  subLabel: {
    fontSize: '16px',
    lineHeight: '30px',
  },
}))

export const AMSelector = ({
  value,
  options,
  onChange,
  size,
  disabled,
  carousel,
}: AMSelectorType) => {
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const height =
    size === SelectorItemSize.Small
      ? 110
      : size === SelectorItemSize.Large && !isMobileView
      ? 300
      : 209
  const classes = useStyles(height)

  return (
    <>
      {carousel ? (
        <Carousel
          autoPlay={false}
          animation={'slide'}
          navButtonsAlwaysVisible={true}
          indicators={false}
        >
          {options.map((option, idx) => (
            <AMSelectorItem
              keyAttr={'selector-item-' + idx}
              selected={value.includes(option.value)}
              disabled={disabled}
              value={option.value}
              size={size}
              onClick={() => onChange(option.value)}
              img={option.img}
              label={option.label}
              subLabel={option.subLabel}
            />
          ))}
        </Carousel>
      ) : (
        <Grid container spacing={2}>
          {options.map((option, idx) => (
            <Grid key={'toggle-' + idx} item xs={12} md={3}>
              <AMSelectorItem
                keyAttr={'selector-item-' + idx}
                selected={value.includes(option.value)}
                disabled={disabled}
                value={option.value}
                size={size}
                onClick={() => onChange(option.value)}
                img={option.img}
                label={option.label}
                subLabel={option.subLabel}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}
