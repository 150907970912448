import { Controller } from 'react-hook-form'
import {
  Direction,
  ExhaustType,
  FormComponent,
  Material,
  SelectorItemSize,
} from '../../utils/types'
import { AMSelector, AMSelectorOption } from '../AMSelector'

type ToggleType = {
  name?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any[]
  options: AMSelectorOption[]
  size?: SelectorItemSize
}

export function AMToggleMultiple({
  defaultValue,
  name,
  options,
  size,
  control,
}: ToggleType & FormComponent) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { onChange, value } }) => (
        <AMSelector
          value={value as Direction[] | Material[] | ExhaustType[]}
          options={options}
          onChange={(s: string) => {
            // in toggleMultiple, value is an array
            const values = value as unknown[]
            if (values.includes(s)) {
              onChange(values.filter((v) => v !== s))
            } else {
              onChange([...values, s])
            }
          }}
          size={size}
        />
      )}
    />
  )
}
