import { makeStyles } from '@material-ui/core/styles'
import { Button, ButtonBaseProps } from '@material-ui/core'

type ButtonType = {
  label: string | JSX.Element
  onClick: (() => void) | ((event: React.MouseEvent<HTMLButtonElement>) => void)
  theme?: ThemeButton
  rounded?: boolean
  circle?: boolean
  variant?: 'text' | 'outlined' | 'contained'
  icon?: JSX.Element
  rightIcon?: JSX.Element
  id?: string
}

export enum ThemeButton {
  Black = 'black',
  White = 'white',
  Red = 'red',
}

const useStyles = makeStyles((theme) => ({
  btnRed: {
    borderRadius: (rounded) => (rounded ? '500px' : '6px'),
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    fontWeight: 'bold',
    height: '48px',
    padding: '20px',
  },
  btnWhite: {
    borderRadius: (rounded) => (rounded ? '500px' : '6px'),
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 'bold',
    boxShadow: 'none',
    border: '2px solid black',
    '&.circle': {
      width: 'auto',
      borderRadius: '25px',
    },
  },
  btnBlack: {
    borderRadius: (rounded) => (rounded ? '500px' : '6px'),
    backgroundColor: 'black',
    color: 'white',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  '& > svg': {
    marginRight: theme.spacing(0.75),
    fontSize: '1.3rem',
  },
}))

export function AMButton({
  label,
  onClick,
  rounded,
  variant,
  theme,
  icon,
  rightIcon,
  type,
  disabled,
  circle,
  id,
}: ButtonType & ButtonBaseProps) {
  variant = variant ?? 'contained'
  const classes = useStyles(rounded === true)

  let classd = classes.btnBlack
  if (theme && theme === ThemeButton.Red) classd = classes.btnRed
  if (theme && theme === ThemeButton.White) classd = classes.btnWhite

  return (
    <Button
      id={id}
      type={type}
      className={`${classd} ${circle === true ? 'circle' : ''}`}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
    >
      {icon} {label} {rightIcon}
    </Button>
  )
}
