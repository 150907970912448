import { useContext } from 'react'
import { AppContext } from '../App'
import { convertSIToUnitSystem } from '../utils/lib'
import { Units } from '../utils/types'

/**
 *
 * Convert SI unit to SI or Imperial (depends of unitSystem selected by user)
 *
 * @param v value to convert
 * @param unit value unit (power, torque...)
 * @param displayMode permit to round in order to have a correct displaying
 * @returns
 */
export const useConvertSIToSystem = (v: number, unit: Units, displayMode?: boolean) => {
  const { unitSystem } = useContext(AppContext)
  return convertSIToUnitSystem(v, unit, unitSystem, displayMode)
}
