import { Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { AMProperty } from '../components/AMProperty'
import { useConvertSIToSystem } from '../hooks/useConvertSIToSystem'
import { useUnitText } from '../hooks/useUnitText'
import { theme } from '../utils/theme'
import { Units } from '../utils/types'

type WorkingPointPanelType = {
  pressure: number
  freeSpeed: number
}

const useStyles = makeStyles(() => ({
  greyCard: {
    backgroundColor: theme.palette.grey[100],
    border: '1px solid ' + theme.palette.grey[300],
    boxShadow: 'none',
    borderRadius: '10px',
    padding: '20px',
  },
}))

export const WorkingPointPanel = ({ pressure, freeSpeed }: WorkingPointPanelType) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Card variant="outlined" className={classes.greyCard}>
      <Typography variant="subtitle1">{t('workingPointRegulated.title')}</Typography>
      <AMProperty
        label={t('workingPointRegulated.subtitleWP').replace(
          '{unit}',
          useUnitText(Units.Pressure) + ''
        )}
        value={pressure > 0 ? pressure : 'N/A'}
      />
      - {t('common.or')} -
      <AMProperty
        label={t('workingPointRegulated.subtitleOptional')}
        value={freeSpeed > 0 ? freeSpeed : 'N/A'}
      />
    </Card>
  )
}
