import { createTheme, CssBaseline, makeStyles, useMediaQuery } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import i18next from 'i18next'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AMFooter } from './components/AMFooter'
import { AMHeader } from './components/AMHeader'
import { Layout } from './Layout'
import { FormPage } from './pages/FormPage'
import { HomePage } from './pages/HomePage'
import { ResultsPage } from './pages/ResultPage'
import { LanguageView } from './panels/LanguageView'
import { mdTheme } from './utils/mdTheme'
import { smTheme } from './utils/smTheme'
import { theme } from './utils/theme'
import { AMConfig, SearchCriteria, UnitSystem } from './utils/types'
import TagManager from 'react-gtm-module'
import { getUrlParam, urlParamToCriterias } from './utils/lib'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > div': {
      marginTop: '50px',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
      },
    },
  },
}))

enum Page {
  Home = 'home',
  Form = 'form',
  Results = 'results',
}
/**
 * By default, app use SI system. All calculs have to be done in SI.
 * Imperial can be introduced only for display values
 */
export const AppContext = createContext<{
  config: AMConfig | null
  setConfig: (config: AMConfig) => void
  unitSystem: UnitSystem
  setUnitSystem: (unit: UnitSystem) => void
}>({
  config: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setConfig: (config: AMConfig) => {},
  unitSystem: UnitSystem.SI,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUnitSystem: (unit: UnitSystem) => {},
})

function App() {
  const classes = useStyles()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  /**
   * global appstate
   * */
  // page is not a "real" page, juste main componant displayed
  const [currentPage, setCurrentPage] = useState<Page>(Page.Form)
  // search
  const [criterias, setCriterias] = useState<SearchCriteria>()
  const [language, setLanguage] = useState('en')
  const [displayLanguageView, setDisplayLanguageView] = useState(false) // only on mobile (language selector)
  const [currentTheme, setCurrentTheme] = useState(isMobileView ? smTheme : mdTheme)
  // context config
  const [config, setConfig] = useState<AMConfig | null>(null)

  const [unitSystem, setUnitSystem] = useState((getUrlParam('unit') as UnitSystem) || UnitSystem.SI)

  const { t } = useTranslation()

  // update page HTML attibutes
  document.title = t('title.tag')
  document.querySelector('meta[name="description"]')?.setAttribute('content', t('title.header'))

  // GTM
  const tagManagerArgs = {
    gtmId: 'GTM-KF6BL7D',
  }
  TagManager.initialize(tagManagerArgs)

  // context
  const ctx = useMemo(
    () => ({
      config: config,
      setConfig: (config: AMConfig) => setConfig(config),
      unitSystem: unitSystem,
      setUnitSystem: (unit: UnitSystem) => setUnitSystem(unit),
    }),
    [config, unitSystem]
  )

  // form submition
  const onSubmit = (newCriterias: SearchCriteria) => {
    setCriterias(newCriterias)

    // go to next page
    setCurrentPage(Page.Results)
  }

  // try to read sharelink with criteria
  if (getUrlParam('shared')) {
    const criteriasFromUrl = urlParamToCriterias()
    if (criteriasFromUrl && !criterias) {
      onSubmit(criteriasFromUrl)
    }
  }

  const updateBackground = () => {
    if (config?.backgroundColor) {
      currentTheme.palette.background.default = config?.backgroundColor
    }
  }
  updateBackground()

  // auto scroll at top when searching
  useEffect(() => window.scrollTo(0, 0), [currentPage])

  // manage changement of dimensions
  useEffect(() => {
    setCurrentTheme(isMobileView ? smTheme : mdTheme)
    if (isMobileView && currentPage === Page.Form) {
      setCurrentPage(Page.Home)
    }
    if (!isMobileView && currentPage === Page.Home) {
      setCurrentPage(Page.Form)
    }
  }, [isMobileView])

  // manage language changes on config when user change language from selector
  useEffect(() => {
    i18next.changeLanguage(language) // -> returns a Promise
    setDisplayLanguageView(false)
  }, [language])

  // when config is loaded
  useEffect(() => {
    updateBackground()
  }, [config])

  const displayHeader = !config || (config && config.header)
  return (
    <AppContext.Provider value={ctx}>
      <ThemeProvider theme={createTheme(currentTheme)}>
        <CssBaseline />
        {displayHeader && (
          <AMHeader
            displayLanguageView={displayLanguageView}
            setDisplayLanguageView={setDisplayLanguageView}
            language={language}
            setLanguage={(lg: string) => setLanguage(lg)}
          />
        )}
        <Layout setLanguage={(lg: string) => setLanguage(lg)}>
          <div className={classes.root}>
            {displayLanguageView && <LanguageView setLanguage={(lg: string) => setLanguage(lg)} />}
            {!displayLanguageView && (
              <>
                {currentPage === Page.Home && (
                  <HomePage onClick={() => setCurrentPage(Page.Form)} />
                )}
                {currentPage === Page.Form && (
                  <FormPage onSubmit={onSubmit} onClickBack={() => setCurrentPage(Page.Home)} />
                )}
                {currentPage === Page.Results && criterias && (
                  <ResultsPage
                    criterias={criterias}
                    onClickBack={() => setCurrentPage(Page.Form)}
                  />
                )}
              </>
            )}
          </div>
        </Layout>
        {displayHeader && <AMFooter />}
      </ThemeProvider>
    </AppContext.Provider>
  )
}

export default App
