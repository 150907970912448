/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AMSeparator } from '../components/AMSeparator'
import { AMTooltip } from '../components/AMTooltip'
import { GraphDiffPressures } from '../components/graphs/GraphDiffPressures'
import { GraphTPA } from '../components/graphs/GraphTPA'
import { useConvertSIToSystem } from '../hooks/useConvertSIToSystem'
import { useUnitText } from '../hooks/useUnitText'
import { theme } from '../utils/theme'
import { AirMotor, GraphType, SearchCriteria, Units } from '../utils/types'
import { AccessoriesView } from './AccessoriesView'
import { Documentation } from './Documentation'
import { MotorCharacteristics } from './MotorCharacteristics'
import { WorkingPointPanel } from './WorkingPointPanel'

type MotorDetailsType = {
  motor: AirMotor | null
  pressure: number
  regulatedPressure: number
  throttle: number
  userTorque: number | undefined
  userSpeed: number | undefined
  criterias: SearchCriteria
}

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h2': {
      padding: 'inherit',
    },
  },
  graphContainer: {
    border: '1px solid ' + theme.palette.grey[300],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    margin: theme.spacing(3) + 'px 0px',
  },
  linkButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}))

export const MotorDetails = ({
  motor,
  pressure,
  regulatedPressure,
  throttle,
  userSpeed,
  userTorque,
  criterias,
}: MotorDetailsType) => {
  if (!motor) {
    return <></>
  }
  const classes = useStyles()
  const [graph, setGraph] = useState<GraphType>(GraphType.TPA)
  const { t } = useTranslation()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const graphListing = [
    GraphType.TPA63,
    GraphType.TPA,
    GraphType.Torque,
    GraphType.Power,
    GraphType.Air,
    GraphType.All,
  ]

  const selectList = graphListing.map((g, idx) => (
    <MenuItem key={`graph-list-` + idx} value={g}>
      {t('graphName.' + g)
        .replace(
          '{pressure}',
          (g === GraphType.TPA63 ? useConvertSIToSystem(6.3, Units.Pressure, true) : pressure) + ''
        )
        .replace('{unit}', ' ' + useUnitText(Units.Pressure) + '')}
    </MenuItem>
  ))

  return (
    <div>
      <Card variant="outlined">
        {!isMobileView && (
          <>
            <div className={classes.header}>
              <Typography variant="h2">{motor.name}</Typography>
              <FormControl variant="outlined">
                <Select
                  id="graph-select"
                  value={graph}
                  onChange={(e: any) => setGraph(e.target.value as GraphType)}
                >
                  {selectList}
                </Select>
              </FormControl>
            </div>

            <div className={classes.graphContainer}>
              {graph !== GraphType.TPA && graph !== GraphType.TPA63 && graph !== GraphType.All && (
                <GraphDiffPressures motor={motor} types={[graph]} />
              )}
              {graph === GraphType.All && (
                <GraphDiffPressures
                  motor={motor}
                  types={[GraphType.Torque, GraphType.Power, GraphType.Air]}
                />
              )}
              {graph === GraphType.TPA && (
                <GraphTPA
                  motor={motor}
                  pressure={pressure}
                  regulatedPressure={regulatedPressure}
                  throttle={throttle}
                  userSpeed={userSpeed}
                  userTorque={userTorque}
                />
              )}
              {graph === GraphType.TPA63 && (
                <GraphTPA
                  motor={motor}
                  pressure={pressure}
                  regulatedPressure={regulatedPressure}
                  throttle={throttle}
                  userSpeed={userSpeed}
                  userTorque={userTorque}
                  useGraph63={true}
                />
              )}
            </div>
          </>
        )}
        <Typography variant="h2">
          {t('details.motorCharacteristics')}{' '}
          <AMTooltip content={t('details.tooltip')} img={'tooltipAir.jpg'} />
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <WorkingPointPanel
              pressure={+regulatedPressure.toFixed(2)}
              freeSpeed={throttle ? Math.round(motor.maxPowerPoint.freeSpeed * throttle) : 0}
            />
          </Grid>
          <Grid item md={7} xs={12}>
            <MotorCharacteristics motor={motor} />
          </Grid>
        </Grid>
        <AMSeparator />
        <AccessoriesView
          accessories={motor.accessories}
          onChange={() => console.log('Not implemented')}
        />
        <AMSeparator />
        <Documentation motor={motor} />
      </Card>
    </div>
  )
}
