import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AMProperty } from '../components/AMProperty'
import { AMSeparator } from '../components/AMSeparator'
import { useUnitText } from '../hooks/useUnitText'
import { capitalizeFirstLetter } from '../utils/lib'
import { SearchCriteria, Units } from '../utils/types'

type CriteriaResumeType = {
  criterias: SearchCriteria
}

const useStyles = makeStyles((theme) => ({
  root: { backgroundColor: 'white', padding: theme.spacing(4) },
}))

export const CriteriasResume = ({ criterias }: CriteriaResumeType) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Typography variant="h2">{t('units.title')}</Typography>
      <Grid container spacing={10}>
        <Grid item md={6} xs={12}>
          <AMProperty label={t('units.title')} value={capitalizeFirstLetter(criterias.unit)} />
        </Grid>
      </Grid>
      <AMSeparator />
      <Typography variant="h2">{t('workingPoint.title')}</Typography>
      <Grid container spacing={10}>
        <Grid item md={6} xs={12}>
          <Typography variant="h4">{t('motor.torque')}</Typography>
          <AMProperty
            label={`${t('motor.torque')} (${useUnitText(Units.Torque)})`}
            value={criterias.torque}
          />
          <AMProperty
            label={`${t('workingPoint.minStartingTorque')} (${useUnitText(Units.Torque)})`}
            value={criterias.minStartTorque}
          />
          <AMProperty
            label={`${t('workingPoint.minStallTorque')} (${useUnitText(Units.Torque)})`}
            value={criterias.minStallTorque}
          />
          <AMProperty
            label={`${t('workingPoint.maxStallTorque')} (${useUnitText(Units.Torque)})`}
            value={criterias.maxStallTorque}
          />
          <Typography variant="h4">{t('motor.speed')}</Typography>
          <AMProperty
            label={`${t('motor.speed')} (${useUnitText(Units.Speed)})`}
            value={criterias.speed}
          />
          <AMProperty
            label={`${t('workingPoint.speedAtMaxPower')} (${useUnitText(Units.Speed)})`}
            value={criterias.speedAtMaxPower}
          />
          <AMProperty
            label={`${t('motor.freeSpeed')} (${useUnitText(Units.Speed)})`}
            value={criterias.freeSpeed}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="h4">{t('motor.power')}</Typography>
          <AMProperty
            label={`${t('motor.power')} (${useUnitText(Units.Power)})`}
            value={criterias.power}
          />
          <Typography variant="h4">{t('motor.pressure')}</Typography>
          <AMProperty label={t('workingPoint.availablePressure')} value={criterias.pressure} />
        </Grid>
      </Grid>
      <AMSeparator />
      <Typography variant="h2">{t('options.title')}</Typography>
      <Grid container spacing={10}>
        <Grid item md={6} xs={12}>
          <AMProperty
            label={t('motor.direction')}
            value={criterias.direction?.map((c) => capitalizeFirstLetter(c)).join(', ')}
          />
          <AMProperty
            label={t('motor.bodyMaterial')}
            value={criterias.material?.map((c) => capitalizeFirstLetter(c)).join(',')}
          />
          <AMProperty
            label={t('motor.exhaustType')}
            value={criterias.exhaustType?.map((c) => capitalizeFirstLetter(c)).join(',')}
          />
        </Grid>
      </Grid>
    </div>
  )
}
