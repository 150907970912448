import { FormControl, Select, MenuItem, makeStyles } from '@material-ui/core'
import languages from '../assets/languages.json'

interface AMLanguageSelectorType {
  language: string
  setLanguage: (lg: string) => void
}

const useStyles = makeStyles((theme) => ({
  languageSelector: {
    backgroundColor: 'white',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px ${theme.spacing(0.5)}px ${theme.spacing(
      3
    )}px`,
    borderRadius: '24px',
    '& .MuiSelect-select.MuiSelect-select': {
      fontWeight: 'bold',
    },
    '& .MuiInput-underline::before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline::after': {
      borderBottom: 'none',
    },
  },
}))

export const AMLanguageSelector = ({ language, setLanguage }: AMLanguageSelectorType) => {
  const classes = useStyles()
  return (
    <div className={classes.languageSelector}>
      <FormControl>
        <Select
          id="language-selector"
          value={language}
          label="language-selector"
          onChange={(event) => setLanguage(event.target.value as string)}
          renderValue={() => language.toUpperCase()}
        >
          {languages.map((lg) => (
            <MenuItem key={'select-' + lg.id} value={lg.id}>
              {lg.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
