import { makeStyles } from '@material-ui/styles'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AMButton, ThemeButton } from '../components/AMButton'
import { SelectOptionsPanel } from '../panels/SelectOptionsPanel'
import { SelectUnitsPanel } from '../panels/SelectUnitsPanel'
import { SelectWorkingPointPanel } from '../panels/SelectWorkingPointPanel'
import { SearchCriteria } from '../utils/types'
import { theme } from '../utils/theme'
import { useMediaQuery } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { AMFixedFooter } from '../components/AMFixedFooter'
import { urlParamToCriterias } from '../utils/lib'

type FormPageType = {
  onSubmit: (criterias: SearchCriteria) => void
  onClickBack: () => void
}

const useStyles = makeStyles(() => ({
  actionsBtn: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      position: 'absolute',
      paddingBottom: '20px',
      zIndex: 999,
      left: 'calc(50% - 115px)',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginBottom: '0px',
    },
  },
  formsSm: {
    marginBottom: '80px', // to avoid fixed footer in mobile mode
  },
}))

export const FormPage = ({ onSubmit, onClickBack }: FormPageType) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { handleSubmit, control, watch, setValue } = useForm<SearchCriteria>()

  urlParamToCriterias()

  // useful for mobile view
  const [step, setStep] = useState<number>(1)
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const prevStep = () => setStep(step - 1)
  const nextStep = () => setStep(step + 1)

  // auto scroll at top when searching
  useEffect(() => {
    window.scrollTo(0, 0)
    if (step === 0) {
      setStep(1)
      onClickBack()
    }
  }, [step])

  return (
    <>
      {!isMobileView ? (
        <>
          <SelectUnitsPanel control={control} watch={watch} setValue={setValue} />
          <SelectWorkingPointPanel control={control} watch={watch} setValue={setValue} />
          <SelectOptionsPanel control={control} />

          <div className={classes.actionsBtn}>
            <AMButton
              type="submit"
              theme={ThemeButton.Red}
              label={t('button.search')}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </>
      ) : (
        <>
          <div className={classes.formsSm}>
            {step === 1 && <SelectUnitsPanel control={control} watch={watch} setValue={setValue} />}
            {step === 2 && (
              <SelectWorkingPointPanel control={control} watch={watch} setValue={setValue} />
            )}
            {step === 3 && <SelectOptionsPanel control={control} />}
          </div>
          {/* Step buttons*/}
          <AMFixedFooter>
            <div className={classes.actionsBtn}>
              <>
                <AMButton theme={ThemeButton.White} label={<ArrowBackIcon />} onClick={prevStep} />
                {step < 3 && (
                  <AMButton
                    theme={ThemeButton.Black}
                    rightIcon={<ArrowForwardIcon />}
                    label={t('button.next')}
                    onClick={nextStep}
                  />
                )}
              </>
              {step === 3 && (
                <AMButton
                  type="submit"
                  theme={ThemeButton.Red}
                  label={t('button.search')}
                  onClick={handleSubmit(onSubmit)}
                />
              )}
            </div>
          </AMFixedFooter>
        </>
      )}
    </>
  )
}
