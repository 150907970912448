import { makeStyles, Theme as AugmentedTheme } from '@material-ui/core/styles'
import { useContext, useMemo } from 'react'
import { AppContext } from './App'
import { checkLg, getConfig, getUrlParam } from './utils/lib'

type LayoutType = {
  children: JSX.Element
  setLanguage: (lg: string) => void
}
const useStyles = makeStyles((theme: AugmentedTheme) => ({
  root: {
    padding: '0px 15%',
    [theme.breakpoints.down('md')]: {
      padding: '0px 10%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px ' + theme.spacing(2) + 'px',
    },
  },
}))
export const Layout = ({ children, setLanguage }: LayoutType) => {
  const { config, setConfig } = useContext(AppContext)
  const classes = useStyles()

  const loadLg = () => {
    const lgQuery = getUrlParam('lang')
    if (lgQuery && checkLg(lgQuery)) {
      setLanguage(lgQuery)
    }
  }

  const loadConfig = async () => {
    const id = getUrlParam('id')
    const newConfig = await getConfig(id)
    if (newConfig && !config) {
      setConfig(newConfig)
    }
  }

  // load lg and config once
  useMemo(() => {
    loadLg()
    loadConfig()
  }, [])

  return <div className={classes.root}>{children}</div>
}
