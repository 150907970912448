import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AMButton, ThemeButton } from '../components/AMButton'
import { AMDialog } from '../components/AMDialog'
import { AMMenu } from '../components/AMMenu'
import { AMProperty } from '../components/AMProperty'
import { theme } from '../utils/theme'
import { AirMotor } from '../utils/types'

type DocumentationType = {
  motor: AirMotor
}

const useStyles = makeStyles(() => ({
  documentation: {
    '& button': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& button:first-of-type': {
      marginTop: theme.spacing(2),
    },
  },
  img: {
    display: 'flex',
    justifyContent: 'center',
    height: '310px',
  },
}))

export const Documentation = ({ motor }: DocumentationType) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const onOpen2DDoc = () => {
    window.open('https://catalogs.cp.com/files/cp/' + motor.documents.D2?.href, '_blank')
  }

  const onOpen3DDoc = () => {
    setOpen(true)
  }

  return (
    <Grid container spacing={4} className={classes.documentation}>
      <Grid item md={4} xs={12}>
        <Typography variant="h2">Documentation</Typography>
        <AMProperty label={t('motor.productName')} value={motor.name} />
        <AMProperty label={t('motor.productNumber')} value={motor.productName} />
        {/* <AMProperty label="Product number CP (debug)" value={motor.productNameCP} /> */}
        <AMButton
          disabled={!motor.documents?.D2}
          theme={ThemeButton.Black}
          label={t('details.dimensionalDrawing')}
          onClick={onOpen2DDoc}
        />
        <AMMenu
          title={t('details.3DDrawing')}
          options={motor.documents?.D3!.map((D3) => ({
            label: D3.name,
            label2: `[${D3.type}]`,
            onClick: () => window.open('https://catalogs.cp.com/files/cp/' + D3.href, '_blank'),
          }))}
        />
      </Grid>
      <Grid item md={8} xs={12} className={classes.img}>
        <img
          src={
            motor.img ? 'https://catalogs.cp.com/img/cp/products/' + motor.img : 'placeholder.png'
          }
          alt="Motor img"
        />
      </Grid>
    </Grid>
  )
}
