import {
  Button,
  colors,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { useState } from 'react'
import { AMButton, ThemeButton } from './AMButton'

type AMMenuOptionsType = {
  label: string
  label2?: string
  onClick: () => void
}
type AMMenuType = {
  title: string
  options: AMMenuOptionsType[]
}

const useStyles = makeStyles((theme) => ({
  btnBlack: {
    borderRadius: '6px',
    backgroundColor: 'black',
    color: 'white',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  listItem: {
    [theme.breakpoints.down('md')]: {
      '& .MuiTypography-body1': {
        fontSize: '100%',
      },
    },
  },
  label2: {
    textAlign: 'end',
    marginLeft: theme.spacing(2),
    color: colors.grey[700],
  },
}))

export const AMMenu = ({ title, options }: AMMenuType) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelect = (option: AMMenuOptionsType) => {
    option.onClick()
    handleClose()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <AMButton
        aria-controls="drawing-3d-menu"
        aria-haspopup="true"
        theme={ThemeButton.Black}
        onClick={handleClick}
        label={title}
      ></AMButton>
      <Menu
        id="drawing-3d-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        autoFocus={false}
      >
        {options.map((option, idx) => (
          <MenuItem
            key={'menu-3d-' + idx}
            className={classes.listItem}
            onClick={() => handleSelect(option)}
          >
            <ListItemText primary={option.label} />
            {option.label2 && <ListItemText className={classes.label2} primary={option.label2} />}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
