import {
  colors,
  createStyles,
  makeStyles,
  Popover,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ShareIcon from '@material-ui/icons/Share'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { criteriasToUrlParams, sendGTMEvent, shortUrl } from '../utils/lib'
import { AirMotor, SearchCriteria } from '../utils/types'
import { AMButton, ThemeButton } from './AMButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& button': {
        marginLeft: '8px',
      },
      '& > *': {
        color: colors.green[700],
      },
    },
    icon: {
      fontSize: '45px',
    },
    linkField: {
      width: '300px',
      '& .MuiOutlinedInput-root': {
        height: '42px',
      },
    },
    copyLink: {
      marginTop: theme.spacing(1),
      fontSize: '42px',
    },
    align: {
      display: 'flex',
      '& > svg': {
        marginLeft: theme.spacing(1),
      },
    },
  })
)

type AMLinkButton = {
  criterias: SearchCriteria
  motor: AirMotor
}

export const AMLinkButton = ({ criterias, motor }: AMLinkButton) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const [link, setLink] = useState<string | null>('')
  const [motorName, setMotorName] = useState<string>('') // motor used to generate link

  const sendGAEvent = (url: string) => {
    sendGTMEvent({
      event: 'share',
      url,
      name: motorName,
    })
  }

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    copyToClipboard(await generateLink())
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickCopy = async () => {
    setCopied(true)
    if (link) copyToClipboard(link)
  }

  const copyToClipboard = async (url: string) => {
    sendGAEvent(url)
    navigator.clipboard.writeText(url)
  }

  const generateLink = async () => {
    // don't regenerate link if motor didn't change
    if (link && motor.name === motorName) return link

    const separator = location.href.includes('?') ? '&' : '?'
    const url = await shortUrl(
      encodeURIComponent(
        location.href +
          separator +
          criteriasToUrlParams(criterias) +
          '&motor=' +
          motor.name +
          '&shared=true'
      )
    )
    setLink(url)
    setMotorName(motor.name)
    return url
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <AMButton
        aria-describedby={id}
        label={
          <div className={classes.align}>
            {t('share.btn')} <ShareIcon />
          </div>
        }
        theme={ThemeButton.Black}
        onClick={handleClick}
      ></AMButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.popover}>
          <CheckCircleIcon className={classes.icon} />
          <Typography variant="h4">{t('share.confirm')}</Typography>
          <Typography variant="h5">{t('share.title')}</Typography>
          <div className={classes.copyLink}>
            <TextField
              disabled
              className={classes.linkField}
              id="outlined-disabled"
              value={link || ''}
              variant="outlined"
            />
            <AMButton
              aria-describedby={id}
              icon={copied ? <CheckIcon /> : undefined}
              label={t('share.copy')}
              theme={ThemeButton.Black}
              onClick={handleClickCopy}
            ></AMButton>
          </div>
        </div>
      </Popover>
    </div>
  )
}
