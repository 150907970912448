import { createTheme } from '@material-ui/core'

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    colors: {
      graph: {
        torque: {
          main: string
          regulPressure: string
          regulThottle: string
        }
        power: {
          main: string
          regulPressure: string
          regulThottle: string
        }
        air: { main: string }
      }
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors: {
      graph: {
        torque: {
          main: string
          regulPressure: string
          regulThottle: string
        }
        power: {
          main: string
          regulPressure: string
          regulThottle: string
        }
        air: { main: string }
      }
    }
  }
}

export const theme = createTheme({
  colors: {
    graph: {
      torque: {
        main: '#FF0000',
        regulPressure: '#ed8eb9',
        regulThottle: '#990849',
      },
      power: {
        main: '#00ff00',
        regulPressure: '#8cff9f',
        regulThottle: '#008215',
      },
      air: { main: '#0000FF' },
    },
  },
})
