import { Typography, Grid, GridSize } from '@material-ui/core'
import { AMStepPanel } from '../components/AMStepPanel'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { AppContext } from '../App'
import { AirMotor, CTAType } from '../utils/types'
import { replaceUrlWithMotorData, sendGTMEvent } from '../utils/lib'

export enum MoreInfos {
  NextSteps,
  NoResults,
}

type MoreInfosPanelType = {
  type: MoreInfos
  motor?: AirMotor | null
}

type Map = {
  type: CTAType
  key: string
  url: string | undefined
}

export const MoreInfosPanel = ({ type, motor }: MoreInfosPanelType) => {
  const { t } = useTranslation()
  const { config } = useContext(AppContext)

  let urlContactUs = config?.cta?.find((c) => c.type === CTAType.ContactUs)?.link
  let urlFindLocator = config?.cta?.find((c) => c.type === CTAType.Locator)?.link
  let urlBuy = config?.cta?.find((c) => c.type === CTAType.Buy)?.link
  let urlCart = config?.cta?.find((c) => c.type === CTAType.Cart)?.link

  // replace dynamic fields
  if (urlContactUs && motor) urlContactUs = replaceUrlWithMotorData(urlContactUs, motor)
  if (urlFindLocator && motor) urlFindLocator = replaceUrlWithMotorData(urlFindLocator, motor)
  if (urlBuy && motor) urlBuy = replaceUrlWithMotorData(urlBuy, motor)
  if (urlCart && motor) urlCart = replaceUrlWithMotorData(urlCart, motor)

  const ctaConfigs = config?.cta?.map((cta) => cta.type) || []
  const sizeBlocks = (12 / ctaConfigs.length) as GridSize // length cannot be more 3

  const mapTradsUrls: Map[] = [
    { type: CTAType.ContactUs, key: 'contact', url: urlContactUs },
    { type: CTAType.Locator, key: 'findDistributor', url: urlFindLocator },
    { type: CTAType.Buy, key: 'buy', url: urlBuy },
    { type: CTAType.Cart, key: CTAType.Cart, url: urlCart },
  ]

  const onClickCTA = (map: Map) => {
    map.url ? window.open(map.url) : undefined
    sendGTMEvent({
      event: 'ctaClick',
      type: map.type,
      url: map.url,
      name: config?.name,
    })
  }
  const getPanel = (type: CTAType) => {
    const map = mapTradsUrls.find((m) => m.type === type)
    if (!map) return <></>

    return (
      <Grid key={map.key} item xs={12} md={sizeBlocks}>
        <AMStepPanel
          id={map.key}
          title={t(`nextStep.${map.key}.title`)}
          labelBtn={t(`nextStep.${map.key}.btn`)}
          onClick={() => onClickCTA(map)}
        >
          <Typography variant="body1">{t(`nextStep.${map.key}.body`)}</Typography>
        </AMStepPanel>
      </Grid>
    )
  }

  if (type === MoreInfos.NoResults) {
    return (
      <Grid item xs={12} md={true}>
        <AMStepPanel
          id={'newResearch'}
          title={t('nextStep.newResearch.title')}
          labelBtn={t('nextStep.newResearch.btn')}
          onClick={undefined}
        >
          <Typography variant="body1">{t('nextStep.newResearch.btn')}</Typography>
        </AMStepPanel>
      </Grid>
    )
  }
  return (
    <Grid container spacing={2} alignItems="stretch">
      {ctaConfigs.map((conf) => getPanel(conf))}
    </Grid>
  )
}
