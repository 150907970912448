import { makeStyles } from '@material-ui/styles'
import { theme } from '../utils/theme'

type FixedFooterType = {
  children: JSX.Element
}

const useStyles = makeStyles(() => ({
  fixedFooter: {
    textAlign: 'center',
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    width: '100%',
    zIndex: 999,
  },
}))

export const AMFixedFooter = ({ children }: FixedFooterType) => {
  const classes = useStyles()

  return <div className={classes.fixedFooter}>{children}</div>
}
