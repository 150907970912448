import { Control } from 'react-hook-form'

export enum GraphType {
  TPA = 'tpa',
  TPA63 = 'tpa63', // pressure blocked at 6.3bar
  Torque = 'torque',
  Power = 'power',
  Air = 'air',
  All = 'all',
}

export enum UnitSystem {
  SI = 'si',
  Imperial = 'imperial',
}

export enum Units {
  Power = 'power',
  Torque = 'torque',
  Pressure = 'pressure',
  AirConsumption = 'airConsumption',
  Weight = 'weight',
  Speed = 'speed',
  OutputSpindle = 'outputSpindle',
}
export enum Direction {
  Clockwise = 'clockwise',
  Reversible = 'reversible',
}

export enum Material {
  Standard = 'standard',
  Stainless = 'stainless',
}

export enum ExhaustType {
  // Indifferent = 'indifferent',
  Key = 'key',
  Thread = 'thread',
  Hexagonal = 'hexagonal',
  Square = 'square',
}

export enum CTAType {
  ContactUs = 'contact-us', // kebab case in order to match with embed.json
  Buy = 'buy',
  Locator = 'locator',
  Cart = 'cart',
}

export enum ImperialValues {
  Power = 'power',
  Torque = 'torque',
  StartTorque = 'startTorque',
  StallTorque = 'stallTorque',
  Weight = 'weight',
}

export type SearchCriteria = {
  unit: UnitSystem
  torque: number | undefined
  speed: number | undefined
  power: number | undefined
  minStartTorque: number | undefined
  minStallTorque: number | undefined
  maxStallTorque: number | undefined
  speedAtMaxPower: number | undefined
  freeSpeed: number | undefined
  pressure: number | undefined
  direction: Direction[]
  material: Material[]
  exhaustType: ExhaustType[]
}

export type FormPanel = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  control: Control<SearchCriteria, object> // TODO : must be required
}

export type FormComponent = {
  label?: string
  name: keyof SearchCriteria
} & FormPanel

export type AirMotor = {
  id: string
  name: string
  productName: string
  productNameCP: string
  power: number
  powerImp: number
  reversible: boolean
  rotationDirection: string | undefined
  stainlessSteel: boolean
  exhaustType: ExhaustType // later type
  maxPowerPoint: {
    torque: number
    torqueImp: number
    speed: number
    power: number
    powerImp: number
    airConsumption: number
    airConsumptionImp: number
    minStatTorque: number
    minStatTorqueImp: number
    stallTorque: number
    stallTorqueImp: number
    freeSpeed: number
  }
  weight: number
  weightImp: number
  exClass?: string | undefined
  ipClass?: string | undefined
  img: string | undefined
  coefficients: {
    torque: number[]
    air: number[]
  }
  accessories: Accessories
  documents: {
    D2: DocumentDB | undefined
    D3: DocumentDB[] | undefined
  }
  lubricationFree: boolean
  outputSpindle: number
  outputSpindleImp: number
}

export type Accessories = {
  mountingFlange: string | undefined
  mountingFoot: string | undefined
  springLoadedShaft: string | undefined
}

export enum AccessoryType {
  MountingFlange = 'mountingFlange',
  MountingFoot = 'mountingFoot',
  SpringLoadedShaft = 'springLoadedShaft',
}

export enum SelectorItemSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type Accessory = {
  type: AccessoryType
  productNumber: number
}

export type DocumentDB = {
  name: string
  href: string
  ext: string
  type: string
}

export type AMConfig = {
  id: number
  name: string
  header: boolean
  backgroundColor: string
  unitSystem: string
  cta: { type: CTAType; link: string }[]
  authorizedDomains: string[]
}
