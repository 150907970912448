import { createTheme } from '@material-ui/core'
import { theme } from './theme'

export const mdTheme = createTheme({
  colors: theme.colors,
  palette: {
    background: {
      default: '#f2f2f2',
    },
    secondary: {
      main: '#ED1C2E',
      light: 'rgba(237, 28, 46, 0.1)',
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: '15px',
      },
    },
    MuiCard: {
      root: {
        backgroundColor: 'white',
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        '& h2': {
          marginTop: theme.spacing(2),
        },
      },
    },
    MuiGrid: {
      root: {
        '& > div': { width: '100%' },
      },
      'grid-xs-12': {
        '& > button': { width: '100%' },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginRight: '-8px',
      },
    },
  },
  typography: {
    fontFamily: 'Arial',
    h1: {
      fontFamily: 'Arial',
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '37px',
      paddingLeft: theme.spacing(3) + 'px',
      paddingBottom: theme.spacing(2),
    },
    h2: {
      fontFamily: 'Arial',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: theme.spacing(3) + 'px',
      paddingBottom: theme.spacing(2),
    },
    h4: {
      fontFamily: 'Arial',
      fontWeight: 'bold',
      fontSize: '17px',
      lineHeight: theme.spacing(5) + 'px',
      // paddingBottom: theme.spacing(2),
    },
    caption: {
      fontSize: '36px',
      lineHeight: '46px',
    },
    subtitle1: {
      fontFamily: 'Arial',
      fontSize: '19px',
      lineHeight: theme.spacing(4) + 'px',
      paddingBottom: '16px',
    },
    subtitle2: {
      fontFamily: 'Arial',
      fontSize: '17px',
      lineHeight: theme.spacing(4) + 'px',
      paddingBottom: '8px',
    },
    h5: {
      fontFamily: 'Arial',
      fontSize: '15px',
      lineHeight: theme.spacing(2) + 'px',
      paddingBottom: '8px',
      paddingTop: '4px',
      fontStyle: 'italic',
    },
    body1: {
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(4) + 'px',
      fontFamily: 'Helvetica',
    },
    body2: {
      fontSize: '16px',
      lineHeight: theme.spacing(4) + 'px',
      fontWeight: 600,
    },
  },
})
