import { makeStyles } from '@material-ui/core'
import { theme } from '../utils/theme'

const useStyles = makeStyles(() => ({
  separator: {
    borderTop: '1px solid ' + theme.palette.grey[300],
    margin: `${theme.spacing(4)}px 0px`,
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(2)}px 0px`,
    },
  },
}))
export const AMSeparator = () => {
  const classes = useStyles()
  return <div className={classes.separator}></div>
}
