import { Card, Typography } from '@material-ui/core'

type AMPanelType = {
  title: string
  subtitle: string | JSX.Element
  children: JSX.Element
}

export const AMPanel = ({ title, subtitle, children }: AMPanelType) => {
  if (typeof subtitle === 'string')
    subtitle = <Typography variant="subtitle1">{subtitle}</Typography>
  return (
    <div>
      <Typography variant="h1">{title}</Typography>
      <Card variant="outlined">
        {subtitle}
        {children}
      </Card>
    </div>
  )
}
