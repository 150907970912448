import { InputAdornment, makeStyles, TextField, TextFieldProps } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormComponent } from '../../utils/types'

type AMTextFieldType = {
  label: string
  computed?: boolean
}

const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiInputLabel-outlined': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: '22px',
    },
  },
  adornment: {
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.light,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    fontWeight: 'bold',
    borderRadius: theme.spacing(0.5),
    fontSize: '13px',
  },
}))

const checkNumberType = (value: string) => {
  return Number(value) || value === '' || value === '0' || value === '0.' || value === '0,'
}
export const AMTextField = ({
  label,
  name,
  control,
  computed,
  type,
}: AMTextFieldType & FormComponent & TextFieldProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          id="outlined-basic"
          inputProps={{}}
          size="small"
          label={label}
          className={classes.textField}
          variant="outlined"
          disabled={computed}
          // any in documentation
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any) => {
            event.target.value = event.target.value.replace(/,/, '.')
            checkNumberType(event.target.value) || type !== 'number' ? onChange(event) : null
          }}
          value={value ?? ''}
          InputProps={{
            inputMode: 'numeric',
            endAdornment:
              computed === true ? (
                <InputAdornment position="end">
                  <span className={classes.adornment}>{t('form.calculatedValue')}</span>
                </InputAdornment>
              ) : null,
          }}
        />
      )}
    />
  )
}
