import { Button, Typography } from '@material-ui/core'
import { makeStyles, Theme as AugmentedTheme } from '@material-ui/core/styles'
import { SelectorItemSize } from '../utils/types'

type AMMotor = {
  keyAttr?: string
  value?: string
  onClick?: (v: string) => void
  selected?: boolean
  size?: SelectorItemSize
  disabled?: boolean
  carousel?: boolean
  img?: string
  label: string
  subLabel?: string
}
const useStyles = makeStyles((theme: AugmentedTheme) => ({
  root: {
    padding: theme.spacing(2),
    color: 'black',
    borderRadius: '4px',
    border: '1px solid ' + theme.palette.grey[300],
    // margin: theme.spacing(1),
    marginLeft: 'inherit',
    height: (height) => height + 'px',
    textTransform: 'inherit',
    backgroundColor: 'white',
    '& > span': { flexDirection: 'column', display: 'flex' },
    '&.selected': {
      color: 'black',
      border: '2px solid black',
    },
    '&.disabled': {
      color: 'black',
      border: 'none',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
    '& img': {
      height: '106px',
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      width: '100%',
    },
  },
  subLabel: {
    fontSize: '16px',
    lineHeight: '30px',
  },
}))

export const AMSelectorItem = ({
  keyAttr,
  disabled,
  value,
  size,
  img,
  label,
  subLabel,
  selected,
  onClick,
}: AMMotor) => {
  const classes = useStyles()

  return (
    <Button
      key={keyAttr ? keyAttr : 'am-selector-item'}
      disabled={disabled}
      className={`${classes.root}  ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
      color="primary"
      onClick={() => {
        onClick && value ? onClick(value) : null
      }}
    >
      {size !== SelectorItemSize.Small && <img src={img ?? 'placeholder.png'} alt="img" />}
      <Typography variant="h2">{label}</Typography>
      {subLabel ?? <span className={classes.subLabel}>{subLabel}</span>}
    </Button>
  )
}
