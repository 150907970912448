import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../App'
import { Units } from '../utils/types'

export const useUnitText = (unit: Units) => {
  const { unitSystem } = useContext(AppContext)
  const { t } = useTranslation()

  if (!unitSystem) return ''
  return t(`unit.${unitSystem}.${unit}`)
}
