import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AMProperty } from '../components/AMProperty'
import { useConvertSIToSystem } from '../hooks/useConvertSIToSystem'
import { useUnitText } from '../hooks/useUnitText'
import { capitalizeFirstLetter } from '../utils/lib'
import { theme } from '../utils/theme'
import { AirMotor, Units } from '../utils/types'

type MotorCharacteristicsType = {
  motor: AirMotor
}

export const MotorCharacteristics = ({ motor }: MotorCharacteristicsType) => {
  const { t } = useTranslation()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      <Typography variant="subtitle1">
        {t('details.performanceAt')} {useConvertSIToSystem(6.3, Units.Pressure, true)}
        {useUnitText(Units.Pressure)}
      </Typography>

      <Grid container spacing={isMobileView ? 2 : 5}>
        <Grid item md={6} xs={12}>
          <Typography variant="subtitle2">{t('details.maxPowerPoint')}</Typography>
          <AMProperty
            label={`${t('motor.torque')} (${useUnitText(Units.Torque)})`}
            value={motor.maxPowerPoint.torque}
          />
          <AMProperty
            label={`${t('motor.speed')} (${useUnitText(Units.Speed)})`}
            value={motor.maxPowerPoint.speed}
          />
          <AMProperty
            label={`${t('motor.power')} (${useUnitText(Units.Power)})`}
            value={motor.power}
          />
          <AMProperty
            label={`${t('motor.airConsumption')} (${useUnitText(Units.AirConsumption)})`}
            value={motor.maxPowerPoint.airConsumption}
          />
          <AMProperty
            label={`${t('motor.startingTorque')} (${useUnitText(Units.Torque)})`}
            value={motor.maxPowerPoint.minStatTorque}
          />
          <AMProperty
            label={`${t('motor.freeSpeed')} (${useUnitText(Units.Speed)})`}
            value={motor.maxPowerPoint.freeSpeed}
          />
          <AMProperty
            label={`${t('motor.stallTorque')} (${useUnitText(Units.Torque)})`}
            value={motor.maxPowerPoint.stallTorque}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="subtitle2">{t('details.others')}</Typography>
          <AMProperty
            label={`${t('motor.weight')} (${useUnitText(Units.Weight)})`}
            value={motor.weight}
          />
          <AMProperty label={`${t('motor.exClass')} `} value={motor.exClass || 'Unknown'} />
          <AMProperty label={`${t('motor.ipClass')} `} value={motor.ipClass || 'Unknown'} />
          <AMProperty label={`${t('motor.reversible')} `} value={motor.reversible ? 'Yes' : 'No'} />
          <AMProperty
            label={`${t('motor.exhaustType')} `}
            value={capitalizeFirstLetter(motor.exhaustType) || 'Unknown'}
          />
          <AMProperty
            label={`${t('motor.outputSpindle')}  (${useUnitText(Units.OutputSpindle)})`}
            value={motor.outputSpindle || 'Unknown'}
          />
          <AMProperty
            label={`${t('motor.lubricationFree')} `}
            value={motor.lubricationFree ? 'Yes' : 'No'}
          />
        </Grid>
      </Grid>
    </div>
  )
}
