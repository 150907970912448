import { Controller } from 'react-hook-form'
import { FormComponent, SelectorItemSize } from '../../utils/types'
import { AMSelector, AMSelectorOption } from '../AMSelector'

type ToggleType = {
  name?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any
  options: AMSelectorOption[]
  size?: SelectorItemSize
}

export function AMToggle({
  defaultValue,
  name,
  options,
  size,
  control,
}: ToggleType & FormComponent) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { onChange, value } }) => (
        <AMSelector
          value={[value]}
          options={options}
          onChange={(s: string) => onChange(s)}
          size={size}
        />
      )}
    />
  )
}
