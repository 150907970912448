import { useContext } from 'react'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../App'
import { useConvertSIToSystem } from '../../hooks/useConvertSIToSystem'
import { useUnitText } from '../../hooks/useUnitText'
import { capitalizeFirstLetter, Fair, Fpower, Ftorque } from '../../utils/lib'
import { theme } from '../../utils/theme'
import { AirMotor, GraphType, Units } from '../../utils/types'

type MotorDetailsType = {
  motor: AirMotor | null
  types: GraphType[]
}

export const GraphDiffPressures = ({ motor, types }: MotorDetailsType) => {
  if (!motor) {
    return <></>
  }

  const { t } = useTranslation()
  const { unitSystem } = useContext(AppContext)

  const getColorByGraphType = (type: GraphType) => {
    switch (type) {
      case GraphType.Air:
        return theme.colors.graph.air.main
      case GraphType.Torque:
        return theme.colors.graph.torque.main
      case GraphType.Power:
        return theme.colors.graph.power.main
      default:
        return 'black'
    }
  }
  const getFnByGraphType = (type: GraphType) => {
    switch (type) {
      case GraphType.Air:
        return Fair
      case GraphType.Torque:
        return Ftorque
      case GraphType.Power:
        return Fpower
    }
  }
  const scalesAxis: Record<
    string,
    {
      display: boolean
      beginAtZero: boolean
      min: number
      title: { display: boolean; text: GraphType }
    }
  > = {}
  const scalesSpeedY: number[] = []
  for (let i = 0; i < 21 + 1; i++) {
    scalesSpeedY.push(+(motor.maxPowerPoint.freeSpeed / 20).toFixed(0) * i)
  }

  const datasetDef = (pressure: number, pts: number[], type: GraphType) => ({
    label: t('graphTitle.generic')
      .replace('{type}', capitalizeFirstLetter(t('motor.' + type)))
      .replace('{pressure}', pressure + ''),
    data: pts,
    fill: false,
    backgroundColor: getColorByGraphType(type),
    borderColor: getColorByGraphType(type),
    yAxisID: 'y-axis-' + type,
  })

  const data: Chart.ChartData = {
    labels: scalesSpeedY,
    datasets: [],
  }

  const pressures = [6.3, 6, 5.5, 5, 4.5, 4].map((p) =>
    useConvertSIToSystem(p, Units.Pressure, true)
  )

  pressures.forEach((p) => {
    types.forEach((type) => {
      const pts: number[] = []
      let precTorque = 1 // just need to be > 0
      scalesSpeedY.forEach((speed) => {
        const ptTorque = Ftorque(motor, speed, p, unitSystem)
        // const fn = getFnByGraphType(type)
        // if (fn) {
        if (type === GraphType.Air) {
          if (precTorque > 0) pts.push(Fair(motor, speed, p, unitSystem))
        } else if (type === GraphType.Power) {
          pts.push(Fpower(motor, speed, p, unitSystem))
        } else if (type === GraphType.Torque) {
          pts.push(Ftorque(motor, speed, p, unitSystem))
        }
        // }
        precTorque = ptTorque
      })
      data.datasets!.push(datasetDef(p, pts, type))
      scalesAxis['y-axis-' + type] = {
        display: true,
        beginAtZero: true,
        min: 0,
        title: {
          display: true,
          text: type,
        },
      }
    })
  })

  const options = {
    interaction: {
      mode: 'index',
      intersect: true,
    },
    elements: {
      point: {
        radius: 2,
      },
    },
    plugins: {
      title: {
        display: true,
        text:
          t('graphTitle.generic')
            .replace('{type}', types.map((type) => capitalizeFirstLetter(t(type))).join(', '))
            .replace('{pressure}', pressures.join(', ')) +
          ' ' +
          useUnitText(Units.Pressure),
      },
      legend: {
        display: false,
      },
    },
    scales: scalesAxis,
    yAxes: [
      {
        gridLines: {
          drawBorder: true,
        },
      },
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    options: {
      aspectRatio: 1,
    },
  }

  return (
    <div>
      <Line id="graph" height={100} data={data} options={options as Chart.ChartOptions} />
    </div>
  )
}
