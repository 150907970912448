import axios from 'axios'
import catalog from '../assets/catalogue.json'
import languages from '../assets/languages.json'
import { AirMotor, AMConfig, Direction, Material, SearchCriteria, Units, UnitSystem } from './types'

export const KgLb = 0.453592
export const NmLbfft = 1.35582
export const KwHp = 0.7457
export const LsCfm = 0.471947
export const BarPsi = 0.0689476

/**
 * Compute power from torque and speed
 * If imperial unit is used, this function convert to SI before calc
 */
export const powerByTorqueSpeed = (torque: number, speed: number, unitSystem: UnitSystem) => {
  let power = 0
  if (unitSystem === UnitSystem.Imperial) {
    const powerImp = +((convertToSI(torque, Units.Torque) * speed * 2 * Math.PI) / 60000).toFixed(3)
    power = convertSIToUnitSystem(powerImp, Units.Power, UnitSystem.Imperial)
  } else {
    power = (torque * speed * 2 * Math.PI) / 60000
  }
  return +power.toFixed(3)
}

/**
 * Compute speed from torque and power
 * If imperial unit is used, this function convert to SI before calc
 */
export const speedByTorquePower = (torque: number, power: number, unitSystem: UnitSystem) => {
  let speed = 0
  if (unitSystem === UnitSystem.Imperial) {
    speed = +(
      (convertToSI(power, Units.Power) * 60000) /
      (convertToSI(torque, Units.Torque) * 2 * Math.PI)
    ).toFixed(3)
  } else {
    speed = (power * 60000) / (torque * 2 * Math.PI)
  }
  return +speed.toFixed(3)
}

/**
 * Compute torque from power and speed
 * If imperial unit is used, this function convert to SI before calc
 */
export const torqueBySpeedPower = (speed: number, power: number, unitSystem: UnitSystem) => {
  let torque = 0
  if (unitSystem === UnitSystem.Imperial) {
    const torqueImp = +((convertToSI(power, Units.Power) * 60000) / (speed * 2 * Math.PI)).toFixed(
      3
    )
    torque = convertSIToUnitSystem(torqueImp, Units.Torque, UnitSystem.Imperial)
  } else {
    torque = (power * 60000) / (speed * 2 * Math.PI)
  }
  return +torque.toFixed(3)
}

/**
 * Search in catalog motors which correspond to user criterias
 * Catalog is in SI unit, so we have to convert criterias to SI in this function
 */
export const search = (criteriasInput?: SearchCriteria): AirMotor[] => {
  const motorsOk: AirMotor[] = []
  if (!criteriasInput) return catalog as unknown as AirMotor[]

  const criterias = getCriteriasInSI(criteriasInput)

  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  ;(catalog as unknown as AirMotor[]).forEach((airMotor: AirMotor) => {
    // required (working point)
    if (criterias.torque) {
      // cannot regulated more than stall torque
      if (airMotor.maxPowerPoint.stallTorque < criterias.torque) return
    }
    if (criterias.speed) {
      // cannot regulated more than free speed
      if (airMotor.maxPowerPoint.freeSpeed < criterias.speed) return
    }
    if (criterias.power) {
      if (airMotor.power < criterias.power) return
    }

    // optionnal (working point)
    if (criterias.minStartTorque) {
      if (
        !airMotor.maxPowerPoint.minStatTorque ||
        criterias.minStartTorque > airMotor.maxPowerPoint.minStatTorque
      )
        return
    }
    if (criterias.minStallTorque) {
      if (
        !airMotor.maxPowerPoint.stallTorque ||
        criterias.minStallTorque > airMotor.maxPowerPoint.stallTorque
      )
        return
    }
    if (criterias.maxStallTorque) {
      if (
        !airMotor.maxPowerPoint.stallTorque ||
        criterias.maxStallTorque < airMotor.maxPowerPoint.stallTorque
      )
        return
    }
    if (criterias.speedAtMaxPower) {
      if (+criterias.speedAtMaxPower !== airMotor.maxPowerPoint.speed) return
    }
    if (criterias.freeSpeed) {
      // +/- 100 => tolerance
      const min = +criterias.freeSpeed - 100
      const max = +criterias.freeSpeed + 100
      if (airMotor.maxPowerPoint.freeSpeed < min || airMotor.maxPowerPoint.freeSpeed > max){
        return
      }
    }

    // options
    if (criterias.direction.length > 0) {
      if (!criterias.direction.includes(Direction.Reversible) && airMotor.reversible) return
      if (!criterias.direction.includes(Direction.Clockwise) && !airMotor.reversible) return
    }
    if (criterias.material.length > 0) {
      if (!criterias.material.includes(Material.Stainless) && airMotor.stainlessSteel) return
      if (!criterias.material.includes(Material.Standard) && !airMotor.stainlessSteel) return
    }
    if (criterias.exhaustType.length > 0) {
      if (!criterias.exhaustType.includes(airMotor.exhaustType)) return
    }

    // if a working point is asked, check if motor can be regulated to reach to working point
    if (criteriasInput.torque && criteriasInput.speed) {
      if (
        canRegulate(
          airMotor,
          criteriasInput.torque,
          criteriasInput.speed,
          criteriasInput.unit,
          criteriasInput.pressure
        )
      ) {
        motorsOk.push(airMotor)
      }
    } else {
      motorsOk.push(airMotor)
    }
  })

  return motorsOk.sort((m1, m2) => {
    // we sort with theorical power (TP), not with real power provided by catalog
    const m1TP = extractTheoricalPowerFromName(m1.name);
    const m2TP = extractTheoricalPowerFromName(m2.name);

    if (m1TP < m2TP) return -1
    if (m1TP === m2TP)
      return m1.maxPowerPoint.freeSpeed < m2.maxPowerPoint.freeSpeed ? -1 : 1
    if (m1TP > m2TP) return 1
    return 1
  })
}

export const extractTheoricalPowerFromName =(name:string) => {
  const regexTP= new RegExp('.{1}[A-Z]?([0-9]{2,4})-.*')
  const tp = name.match(regexTP)
 if (Array.isArray(tp) && tp.length > 1)
 {
   // some motor are named M2501, but are M25too. So, we drop 01 when it is at the end
 return +(tp[1].replace(/01$/, ''))
}
console.error("Unable to extract theorical power from name : ", name)
 return 0
}
/**
 * Try to access to working point on a motor with 2 differents regulations
 * @param motor which motor
 * @param torque desirated torque
 * @param speed  desirated speed
 * @param unitSystem unit system un sue
 * @param maxPressure max pressure to admit
 * @returns
 */
export const canRegulate = (
  motor: AirMotor,
  torque: number,
  speed: number,
  unitSystem: UnitSystem,
  maxPressure?: number
) => {
  const pressureRegulation = searchPressureForUserTorque(
    motor,
    torque,
    speed,
    unitSystem,
    maxPressure
  )
  const throtleRegulation = searchThrottleForUserTorque(
    motor,
    torque,
    speed,
    unitSystem,
    maxPressure
  )
  return pressureRegulation || throtleRegulation
}

// use his function to test linear function on motor with coefficients
export const FtorqueT = (
  motor: AirMotor,
  speed: number,
  p: number,
  unitSystem: UnitSystem,
  throttle = 1
) => {
  const a = { ...motor, coefficients: { torque: [], air: [] } }
  return Ftorque(a, speed, p, unitSystem, throttle)
}

/**
 * Compute torque for a motor from a speed
 * @param motor
 * @param speed
 * @param p pressure to admit
 * @param unitSystem unit system used
 * @param throttle throttle to admit - optional
 * @returns
 */
export const Ftorque = (
  motor: AirMotor,
  speed: number,
  p: number,
  unitSystem: UnitSystem,
  throttle = 1
) => {
  // throttle = throttle ?? 1
  // remember : throttle is ]0,1]
  // in using throttle, torque curse will be more steep. So, we divide by throttle
  if (throttle) speed = speed / throttle
  speed = speed / getSpeedFactor(p, unitSystem)

  if (!motor.coefficients.torque[0]) {
    // no coefficients, so we simulate a linear function
    const coef = (motor.maxPowerPoint.stallTorque - 0) / (0 - motor.maxPowerPoint.freeSpeed)
    return (coef * speed + motor.maxPowerPoint.stallTorque) * getTorqueFactor(p, unitSystem)
  } else {
    // warning, coefficents will give torque in SI !
    const torque =
      (motor.coefficients.torque[0] +
        motor.coefficients.torque[1] * speed +
        motor.coefficients.torque[2] * speed * speed +
        motor.coefficients.torque[3] * speed * speed * speed) *
      getTorqueFactor(p, unitSystem)
    return convertSIToUnitSystem(torque, Units.Torque, unitSystem)
  }
}

/**
 *  Compute power for a motor from a speed
 */
export const Fpower = (
  motor: AirMotor,
  speed: number,
  p: number,
  unitSystem: UnitSystem,
  throttle?: number
) => {
  const torque = Ftorque(motor, speed, p, unitSystem, throttle)

  return powerByTorqueSpeed(torque, speed, unitSystem)
}

/**
 *  Compute air consumption for a motor from a speed
 */
export const Fair = (motor: AirMotor, speed: number, p: number, unitSystem: UnitSystem) => {
  if (!motor.coefficients.air.length) return 0

  // warning, coefficents will give torque in SI !
  const cons =
    (motor.coefficients.air[0] +
      motor.coefficients.air[1] * speed +
      motor.coefficients.air[2] * speed * speed +
      motor.coefficients.air[3] * speed * speed * speed) *
    getAirFactor(p, unitSystem)
  return convertSIToUnitSystem(cons, Units.AirConsumption, unitSystem)
}

const getTorqueFactor = (p: number, unitSystem: UnitSystem) => {
  if (unitSystem === UnitSystem.Imperial) {
    p = convertToSI(p, Units.Pressure)
  }
  if (p > 6.299999) return 1
  return p / 6.3
}

const getSpeedFactor = (p: number, unitSystem: UnitSystem) => {
  if (unitSystem === UnitSystem.Imperial) {
    p = convertToSI(p, Units.Pressure)
  }
  if (p > 6.299999) return 1
  return 0.2116277 + 0.2130721 * p - 1.272238e-2 * p * p - 2.097011e-4 * p * p * p
}

const getAirFactor = (p: number, unitSystem: UnitSystem) => {
  if (unitSystem === UnitSystem.Imperial) {
    p = convertToSI(p, Units.Pressure)
  }
  if (p > 6.299999) return 1
  return 0.1529647 + 0.0201782 * p + 3.195383e-2 * p * p - 2.177462e-3 * p * p * p
}

/**
 * Get pression to use to regulate motor at a workpoint point
 */
export const searchPressureForUserTorque = (
  motor: AirMotor,
  userTorque: number,
  userSpeed: number,
  unitSystem: UnitSystem,
  maxPressure?: number
) => {
  maxPressure = maxPressure || (unitSystem === UnitSystem.SI ? 6.3 : 91)
  userTorque = +userTorque
  userSpeed = +userSpeed
  // if torque at 6.3 bar if < desired torque => unable to regulate
  if (Ftorque(motor, userSpeed, maxPressure, unitSystem) < +userTorque) {
    return 0
  }
  for (let p = maxPressure; p >= 1; p -= 0.01) {
    const currentTorque = Ftorque(motor, userSpeed, p, unitSystem)
    if (currentTorque <= userTorque) {
      return p + 0.01
    }
  }
  return 0
}

/**
 * Get throttle to use to regulate motor at a workpoint point
 */
export const searchThrottleForUserTorque = (
  motor: AirMotor,
  userTorque: number,
  userSpeed: number,
  unitSystem: UnitSystem,
  maxPressure?: number
) => {
  maxPressure = maxPressure ?? (unitSystem === UnitSystem.SI ? 6.3 : 91)
  // if torque with no throttle if < desired torque => unable to regulate
  if (Ftorque(motor, userSpeed, maxPressure, unitSystem, 1) < userTorque) {
    return 0
  }

  const scale = 0.001
  for (let i = 1; i > 0.1; i -= scale) {
    const currentTorque = Ftorque(motor, userSpeed, maxPressure, unitSystem, i)
    if (currentTorque < userTorque) {
      return i + scale
    }
  }
  return 0
}

type Callback<A> = (args: A) => void

/**
 * Transform callback function to promise
 */
export const promisify =
  <T, A>(fn: (args: T, cb: Callback<A>) => void): ((args: T) => Promise<A>) =>
  (args: T) =>
    new Promise((resolve) => {
      fn(args, (callbackArgs) => {
        resolve(callbackArgs)
      })
    })

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

/**
 * Convert a SI unit to SI/Imperial
 * (Yes, number will not changed if already en SI)
 */
export const convertSIToUnitSystem = (
  v: number,
  unit: Units,
  unitSystem: UnitSystem,
  displayMode?: boolean
) => {
  if (unitSystem === UnitSystem.SI) return v
  else {
    switch (unit) {
      case Units.AirConsumption:
        v = +(v / LsCfm)
        if (displayMode) v = +v.toFixed(2)
        break
      case Units.Torque:
        v = +(v / NmLbfft)
        if (displayMode) v = +v.toFixed(2)

        break
      case Units.Power:
        v = +((v / KwHp) /*/ 1000*/)
        if (displayMode) v = +v.toFixed(4)

        break
      case Units.Pressure:
        v = +(v / BarPsi)
        if (displayMode) v = +v.toFixed(0)

        break
      case Units.Weight:
        v = +(v / KgLb)
        if (displayMode) v = +v.toFixed(2)

        break
    }
    return v
  }
}

/**
 * Convert user criteria in SI
 * (No modification if already in SI)
 */
export const getCriteriasInSI = (criterias: SearchCriteria): SearchCriteria => {
  const copy = { ...criterias }
  if (criterias.unit === UnitSystem.SI) return copy

  copy.maxStallTorque = convertToSI(criterias.maxStallTorque, Units.Torque)
  copy.minStallTorque = convertToSI(criterias.minStallTorque, Units.Torque)
  copy.minStartTorque = convertToSI(criterias.minStartTorque, Units.Torque)
  copy.power = convertToSI(criterias.power, Units.Power)
  copy.pressure = convertToSI(criterias.pressure || 91, Units.Pressure)
  copy.torque = convertToSI(criterias.torque, Units.Torque)

  return copy
}

/**
 * Convert a imperial unit to SI
 */
const convertToSI = (v: number | undefined, unit: Units) => {
  if (!v) return 0

  switch (unit) {
    case Units.AirConsumption:
      v = +(v * LsCfm)
      break
    case Units.Torque:
      v = +(v * NmLbfft)
      break
    case Units.Power:
      v = +(v * KwHp)
      break
    case Units.Pressure:
      v = +(v * BarPsi)
      break
    case Units.Weight:
      v = +(v * KgLb)
      break
  }
  return v
}

export const checkLg = (lg: string | null) => {
  if (!lg) return false
  const lgAvailable = languages.map((l) => l.id)

  return lgAvailable.includes(lg)
}

export const getConfig = async (id: string | null): Promise<AMConfig | null> => {
  if (!id || !Number(id)) return null

  const embeds = await (await fetch('embeds.json')).json()

  const config = embeds.embeds.find((c: AMConfig) => c.id === +id)
  return config
    ? {
        id: config.id,
        name: config.name,
        header: config.header,
        backgroundColor: config['background-color'],
        unitSystem: config['unit-system'],
        cta: config.cta,
        authorizedDomains: config['authorized-domains'],
      }
    : null
}

export const getMotorInUnit = (motor: AirMotor, unitSystem: UnitSystem) => {
  const motorInUnitSystem = {
    ...motor,
    maxPowerPoint: { ...motor.maxPowerPoint },
  }
  if (unitSystem === UnitSystem.Imperial) {
    motorInUnitSystem.power = motor.powerImp
    motorInUnitSystem.maxPowerPoint.stallTorque = motor.maxPowerPoint.stallTorqueImp
    motorInUnitSystem.maxPowerPoint.minStatTorque = motor.maxPowerPoint.minStatTorqueImp
    motorInUnitSystem.maxPowerPoint.torque = motor.maxPowerPoint.torqueImp
    motorInUnitSystem.weight = motor.weightImp
    motorInUnitSystem.outputSpindle = motor.outputSpindleImp
    motorInUnitSystem.power = motor.powerImp
    motorInUnitSystem.maxPowerPoint.airConsumption =
      motor.maxPowerPoint.airConsumptionImp ||
      convertSIToUnitSystem(
        motor.maxPowerPoint.airConsumption,
        Units.AirConsumption,
        UnitSystem.Imperial,
        true
      )
  }
  return motorInUnitSystem
}

/**
 * Use datalayer to send GTM event
 * @param data : any because we can  send anything
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendGTMEvent = (data: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const w: any = window // cast now lighting codebase
  w.dataLayer = w.dataLayer || []
  w.dataLayer.push({ ...data, pageName: document.title })
}

export const replaceUrlWithMotorData = (url: string, motor: AirMotor) => {
  return url.replace('{partNumber}', motor.productName).replace('{model}', motor.name)
}

export const getUrlParam = (param: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const url = new URL((window as any).location)
  return url.searchParams.get(param)
}

export const urlParamToCriterias = (): SearchCriteria | undefined => {
  if (!getUrlParam('shared')) return undefined
  // !! validation
  const params = new URLSearchParams(location.search).toString()

  const a = JSON.parse(
    '{"' + params.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      // array values are xx,yyy,zzz format in url
      const d = decodeURIComponent(value)
      return key === '' ? value : d.includes(',') ? d.split(',') : d
    }
  )

  // particuliar case of arrays (if only one element, will be considered as string)
  a.direction = a.direction && !Array.isArray(a.direction) ? [a.direction] : a.direction
  a.material = a.material && !Array.isArray(a.material) ? [a.material] : a.material
  a.exhaustType = a.exhaustType && !Array.isArray(a.exhaustType) ? [a.exhaustType] : a.exhaustType

  const criterias: SearchCriteria = {
    unit: a.unit,
    torque: a.torque,
    speed: a.speed,
    power: a.power,
    minStartTorque: a.minStartTorque,
    minStallTorque: a.minStallTorque,
    maxStallTorque: a.maxStallTorque,
    speedAtMaxPower: a.speedAtMaxPower,
    freeSpeed: a.freeSpeed,
    pressure: a.pressure,
    direction: a.direction || [],
    material: a.material || [],
    exhaustType: a.exhaustType || [],
  }

  return criterias
}

export const criteriasToUrlParams = (criterias: SearchCriteria) => {
  const criteriasWithoutNull = Object.fromEntries(
    Object.entries(criterias).filter(([_, v]) => v != undefined)
  )
  return new URLSearchParams(criteriasWithoutNull as Record<string, string>).toString()
}

export const shortUrl = async (url: string): Promise<string> => {
  try {
    const res = await axios.get(
      'https://qr.cp.com/yourls-api.php?signature=0926d44617&action=shorturl&format=json&url=' + url
    )
    return res.data.shorturl || url
  } catch (e) {
    console.error(e)
    return url
  }
}
