import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { theme } from '../utils/theme'
import { AMButton, ThemeButton } from './AMButton'
import { AMLanguageSelector } from './AMLanguageSelector'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { useContext } from 'react'
import { AppContext } from '../App'

interface AMHeaderType {
  displayLanguageView: boolean
  setDisplayLanguageView: (b: boolean) => void
  language: string
  setLanguage: (lg: string) => void
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'black',
    color: 'white',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '30px',
    '& img': {
      margin: theme.spacing(1),
    },
  },
  identity: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '& > * ': {
      marginLeft: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      '& > * ': {
        marginLeft: theme.spacing(1),
      },
    },
  },
}))

export function AMHeader({
  displayLanguageView,
  setDisplayLanguageView,
  language,
  setLanguage,
}: AMHeaderType) {
  const classes = useStyles()
  const { t } = useTranslation()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={classes.root}>
      <div className={classes.identity}>
        <img src={isMobileView ? 'squaredLogo.png' : 'logo.png'} alt="logo" />
        <div>{isMobileView ? t('title.headerShort') : t('title.header')}</div>
      </div>
      {isMobileView && (
        <AMButton
          theme={ThemeButton.Black}
          label={displayLanguageView ? <CloseIcon /> : <MenuIcon />}
          onClick={() => {
            setDisplayLanguageView(!displayLanguageView)
          }}
          circle
        />
      )}
      {!isMobileView && <AMLanguageSelector language={language} setLanguage={setLanguage} />}
    </div>
  )
}
