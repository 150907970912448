import { Grid } from '@material-ui/core'
import { useContext } from 'react'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../App'
import { useConvertSIToSystem } from '../../hooks/useConvertSIToSystem'
import { useUnitText } from '../../hooks/useUnitText'
import { capitalizeFirstLetter, Fair, Fpower, Ftorque } from '../../utils/lib'
import { theme } from '../../utils/theme'
import { AirMotor, GraphType, Units } from '../../utils/types'
import LegendTPA from './LegendTPA'

type MotorDetailsType = {
  motor: AirMotor | null
  pressure: number
  regulatedPressure: number
  throttle: number
  userTorque: number | undefined
  userSpeed: number | undefined
  useGraph63?: boolean // if we want to display TPA for 6.3bar
}

type Category = {
  label: string;
}

type DataType = {
  unit: Units;
} & Chart.ChartDataSets

type CurveData = {
  category: Category;
  data: DataType[];
}

export const GraphTPA = ({
  motor,
  pressure,
  regulatedPressure,
  throttle,
  userSpeed,
  userTorque,
  useGraph63,
}: MotorDetailsType) => {
  if (!motor) {
    return <></>
  }

  const { t } = useTranslation()
  const { unitSystem } = useContext(AppContext)

  const defaultPressure = useConvertSIToSystem(6.3, Units.Pressure, true)
  const ptsTorque: number[] = []
  const ptsTorqueT: number[] = []
  const ptsPower: number[] = []
  const ptsTorqueRegulatedPressure: number[] = []
  const ptsPowerRegulatedPressure: number[] = []
  const ptsTorqueThrottle: number[] = []
  const ptsPowerThrottle: number[] = []
  const ptsAir: number[] = []

  const scalesSpeedY = []

  for (let i = 0; i < 22; i++) {
    // some calc juste to have a nice scale view
    const coef =
      motor.maxPowerPoint.freeSpeed >= 3000
        ? 2000
        : motor.maxPowerPoint.freeSpeed > 1000
        ? 1000
        : motor.maxPowerPoint.freeSpeed > 100
        ? 100
        : 10
    const max = Math.ceil(motor.maxPowerPoint.freeSpeed / coef) * coef
    scalesSpeedY.push(+(max / 20).toFixed(0) * i)
  }

  let precTorque = 1 // just need to be > 0

  // lets calc torque / power for each speed
  scalesSpeedY.forEach((speed) => {
    const ptTorque = Ftorque(motor, speed, useGraph63 ? defaultPressure : pressure, unitSystem)
    ptsTorque.push(ptTorque)
    ptsPower.push(Fpower(motor, speed, useGraph63 ? defaultPressure : pressure, unitSystem))

    // to test linear function
    // const ptTorqueT = FtorqueT(motor, speed, useGraph63 ? defaultPressure : pressure)
    // ptsTorqueT.push(ptTorqueT)

    // curves for regulation by pressure
    if (regulatedPressure) {
      ptsTorqueRegulatedPressure.push(Ftorque(motor, speed, regulatedPressure, unitSystem))
      ptsPowerRegulatedPressure.push(Fpower(motor, speed, regulatedPressure, unitSystem))
    }

    // curves for regulation by throttle
    if (throttle) {
      if (!ptsTorqueThrottle.some((p) => p < 0)) {
        ptsTorqueThrottle.push(Ftorque(motor, speed, pressure, unitSystem, throttle))
        ptsPowerThrottle.push(Fpower(motor, speed, pressure, unitSystem, throttle))
      }
    }

    // air curve, stop to draw when torque < 0
    if (motor.coefficients.air && precTorque > 0) {
      ptsAir.push(Fair(motor, speed, pressure, unitSystem))
    }
    precTorque = ptTorque
  })

  const types = [GraphType.Power, GraphType.Torque, GraphType.Air]

  // plugin to add workingpoint on graph
  const plugins: Chart.PluginServiceRegistrationOptions[] = [
    {
      // typing pb in lib
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      afterDraw: (chart: any) => {
        if (!userSpeed || !userTorque) return
        const { ctx } = chart

        // Y when torque is max (in px) (= when speed is 0)
        const minPxY = chart.scales['y-axis-M'].top
        // Y when torque  = <last label> (in px)
        const maxPxY = chart.scales['y-axis-M'].bottom

        // 0 when speed = 0 (in px)
        const minPxX = chart.scales.x.left
        // Y when speed = <last label> (in px)
        const maxPxX = chart.scales.x.right

        // label values, so, here is min and max values for torque and speed
        // ! different of motor max values ! It is max for chart here

        // max speed on chart
        const maxX = +chart.scales.x.ticks[chart.scales.x.ticks.length - 1].label
        //max
        const maxY = +chart.scales['y-axis-M'].ticks[
          chart.scales['y-axis-M'].ticks.length - 1
        ].label.replace(',', '.')

        const X = ((maxPxX - minPxX) / (maxX - 0)) * userSpeed + minPxX
        const Y = ((maxPxY - minPxY) / (maxY - 0)) * (maxY - userTorque) + minPxY

        ctx.save()
        ctx.beginPath()
        ctx.arc(X, Y, 10, 0, 2 * Math.PI)
        ctx.lineWidth = 2
        ctx.strokeStyle = 'red'
        ctx.stroke()
        ctx.restore()
      },
    },
  ]

  const curvesData: CurveData[] = [
    {
      category: { 
        label: t('categories.catalogueData')
      }, 
      data: [
        {
          label: t('motor.torque'),
          data: ptsTorque,
          fill: false,
          borderWidth: 1,
          borderDash: [3, 3],
          backgroundColor: 'white',
          borderColor: theme.colors.graph.torque.main,
          yAxisID: 'y-axis-M',
          unit: Units.Torque,
        },
        {
          label: t('motor.power'),
          data: ptsPower,
          fill: false,
          borderWidth: 1,
          borderDash: [3, 3],
          backgroundColor: 'white',
          borderColor: theme.colors.graph.power.main,
          yAxisID: 'y-axis-P',
          // stepped: false,
          cubicInterpolationMode: 'monotone',
          unit: Units.Power
        },
        {
          label: 'Air',
          data: ptsAir,
          fill: false,
          borderWidth: 1,
          borderDash: [3, 3],
          backgroundColor: 'white',
          borderColor: theme.colors.graph.air.main,
          yAxisID: 'y-axis-A',
          unit: Units.AirConsumption
        },
      ]
    },
    {
      category: {
        label: t('categories.airPressureRegulation')
      },
      data: [
        {
          label: `${t('motor.torque')} ${t('details.byReducingAirPressure')}`,
          data: ptsTorqueRegulatedPressure,
          fill: false,
          borderWidth: 2,
          borderDash: [16, 8],
          backgroundColor: 'white',
          borderColor: theme.colors.graph.torque.regulPressure,
          yAxisID: 'y-axis-M',
          unit: Units.Pressure
        },
        {
          label: `${t('motor.power')} ${t('details.byReducingAirPressure')}`,
          data: ptsPowerRegulatedPressure,
          fill: false,
          borderWidth: 2,
          borderDash: [16, 8],
          backgroundColor: 'white',
          borderColor: theme.colors.graph.power.regulPressure,
          yAxisID: 'y-axis-P',
          cubicInterpolationMode: 'monotone', // TYPING PB
          unit: Units.Pressure
        },
      ]
    },
    {
      category: {
        label: t('categories.airFlowRegulation')
      },
      data: [
        {
          label: `${t('motor.torque')} ${t('details.byReducingFreeSpeed')}`,
          data: ptsTorqueThrottle,
          fill: false,
          borderWidth: 3,
          radius: 0,
          backgroundColor: 'white',
          borderColor: theme.colors.graph.torque.regulThottle,
          yAxisID: 'y-axis-M',
          unit: Units.Speed
        },

        {
          label: `${t('motor.power')} ${t('details.byReducingFreeSpeed')}`,
          data: ptsPowerThrottle,
          fill: false,
          borderWidth: 3,
          backgroundColor: 'white',
          borderColor: theme.colors.graph.power.regulThottle,
          yAxisID: 'y-axis-P',
          cubicInterpolationMode: 'monotone', // TYPING PB
          unit: Units.Speed
        },
      ]
    }
  ];


  let datasets: Chart.ChartDataSets[] = []
  curvesData.forEach((curve) => {
    datasets = [...datasets, ...curve.data]
  })
  const data: Chart.ChartData = {
    labels: scalesSpeedY,
    datasets,
  }

  const options = {
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'right',
        maxWidth: 1000,
        labels: {
          boxHeight: 10,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        callbacks: {
          title: (items: Chart.ChartTooltipItem[]) => {
            const speedUnit = t(`unit.${unitSystem}.${Units.Speed}`)
            return `Speed ${items[0].label} ${speedUnit}`;
          },
          label: (item: any) => {
            const unit = t(`unit.${unitSystem}.${item?.dataset?.unit}`);
            return `${item?.formattedValue} ${unit}`
          }
        },
      },
      title: {
        display: true,
        text:
          t('graphTitle.generic')
            .replace(
              '{type}',
              types.map((type) => capitalizeFirstLetter(t('motor.' + type))).join(', ')
            )
            .replace('{pressure}', (useGraph63 ? defaultPressure : pressure) + '') +
          ' ' +
          useUnitText(Units.Pressure),
      },
    },
    scales: {
      'y-axis-M': {
        display: true,
        beginAtZero: true,
        min: 0,
        title: {
          display: true,
          text: `${t('motor.torque')} (${useUnitText(Units.Torque)})`,
        },
      },
      'y-axis-P': {
        display: true,
        beginAtZero: true,
        min: 0,
        title: {
          display: true,
          text: `${t('motor.power')} (${useUnitText(Units.Power)})`,
        },

        position: 'right',
      },
      'y-axis-A': {
        display: true,
        beginAtZero: true,
        min: 0,
        title: {
          display: true,
          text: `${t('motor.air')} (${useUnitText(Units.AirConsumption)})`,
        },

        position: 'right',
      },
    },
    yAxes: [
      {
        gridLines: {
          drawBorder: true,
        },
      },
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    options: {
      aspectRatio: 1,
    },
  }

  return (
    <Grid container>
      <Grid item lg={9}>
        <Line
          id="graph"
          height={100}
          data={data}
          options={options as Chart.ChartOptions}
          plugins={plugins}
        />
      </Grid>
      <Grid item container lg={3}>
        {curvesData?.map((dataset) => (
              <Grid key={dataset.category.label} item md={4} lg={12}>
                <LegendTPA data={dataset.data} category={dataset.category.label} />
              </Grid>
          ))
        }
      </Grid>
    </Grid>
  )
}
