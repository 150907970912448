import { makeStyles, Typography } from '@material-ui/core'

type WorkingPointPanelType = {
  label: string
  value: string | number | undefined
}
const useStyles = makeStyles(() => ({
  property: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))
export const AMProperty = ({ label, value }: WorkingPointPanelType) => {
  const classes = useStyles()

  if (typeof value === 'undefined' || value == '') value = '-'
  return (
    <div className={classes.property}>
      <Typography variant="body1">{label}</Typography>
      <Typography variant="body2">{value}</Typography>
    </div>
  )
}
